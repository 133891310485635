
import axios from "axios";
// import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");



// ---------------------------------------------------------------------------------------------------------
// for fetching data to the auction selector
// ---------------------------------------------------------------------------------------------------------

export const getSelectors = () => {
    return (dispatch) => {
      dispatch(getSelectorsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getSelectors`, axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(getSelectorsSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(getSelectorsFailure(err.message));
        });
    };
  };
  
  const getSelectorsStarted = () => ({
    type: "getSelectors_started",
  });
  
  const getSelectorsSuccess = (data) => ({
    type: "getSelectors_success",
    payload: {
      ...data,
    },
  });
  
  const getSelectorsFailure = (error) => ({
    type: "getSelectors_failure",
    payload: {
      error,
    },
  });
  
