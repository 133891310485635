import React, { useEffect, useState, } from "react";
import "../../../css/loadingLists.css";
import Select from "react-select";
import { connect } from "react-redux";
import Loader from "../../elements/Loader"
import Checkbox from '../../elements/Checkbox';
import { useTranslation } from 'react-i18next'

import {
  getSelectors,
  getAutosByWarehouse,
  makeLoadingList,
  hideConfirmListModal,
  showConfirmListModal,
  getSelectedAutosConfirm,
  countLoadingLists,
  consigneeSelector,
} from "../../../redux/actions";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import {useNavigate} from "react-router-dom";
import {can} from "../../utils/roleUtils";
function CreateLoadingList(props) {

  const [autoData, setAutoData] = useState({ point_of_loading: null , consignee_name:''});


  useEffect(() => {

    setAutoData({...autoData,point_of_loading:{label: "GA", value: "GA"}})
    props.consigneeSelector();
    props.getSelectors();
    props.countLoadingLists();

    props.getAutosByWarehouse("GA",props.currentPage);
    
  }, [props.loadingListCreate,props.consigneeRegistered]);



  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


  // const [autoList, setAutoList] = useState([]);

  // const [checked, setChecked] = useState([false, false, false]);


  const [isCheckAll, setIsCheckAll] = useState(false);   
  const [isCheck, setIsCheck] = useState([]);

  const { t } = useTranslation() 


  const navigate = new useNavigate();



  const PointLoadingDefaultValue = { value: "GA", label: "GA" };





  let landingPointOptions;
  if (props?.SelectorsFetch?.warehouse) {
    landingPointOptions = props.SelectorsFetch?.warehouse.map(function (data) {
      return { value: data.warehouse_name, label: data.warehouse_name };
    });
  }


  let consigneeOptions;
  if (props?.consigneePicker?.consignees) {
    consigneeOptions = props.consigneePicker?.consignees.map(function (data) {
      return { value: data.consignee_name, label: data.consignee_name };
    });
  }



  const handleChangeSelectors = (e, selectorName) => {
    setIsCheck([]);
    let warehouse = e.value;
    props.getAutosByWarehouse(warehouse);

    let selectName = selectorName.name.toString();
    setAutoData({ ...autoData, [selectName]: e });
    console.log(isCheck)
  };

  const handleChangeConsigneeSelector = (e, selectorName) => {
    let selectName = selectorName.name.toString();
    setAutoData({ ...autoData, [selectName]: e });
  };

  // const handleMoreInfoSelectors = (e,selectorName) => {
  //   setAutoAdditionalDataErrors({auto_catagory_error:''})
  //   // console.log(autoRegistrationData)
  // let selectName = selectorName.name.toString();
  // setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [selectName]:e });

  // };

// for selecting all check boxes





const handleSelectAll = e => {
  setIsCheckAll(!isCheckAll);
  setIsCheck(props.autosDataByWarehouse?.data?.data.map(li => li.real_auto_id));
  if (isCheckAll) {
    setIsCheck([]);
  }
};

const handleClick = e => {
  const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
 
};



// this is for the purpose of multiple check

  // const handleCheck = (event,index) => {

    
  //   var autosList_array = [...autoList];
  //   if (event.target.checked) {
  //     autosList_array = [...autoList, event.target.value];
  //   } else {
  //     autosList_array.splice(autoList.indexOf(event.target.value), 1);
  //   }
  //   setAutoList(autosList_array);

  //   const _checked = [...checked];
  //   _checked[index] = event.target.checked;
  //   setChecked(_checked);


  // };

  const createLoadingList = () => {
    let consignee_name = autoData.consignee_name.value||''

    if (isCheck.length > 0) {
      props.makeLoadingList(isCheck,consignee_name);
      navigate('/home/LoadingListsSection/pendingMode/pending');
    } else {
      Swal.fire({
        position: "top-end",
        icon: "info",
        text: "Please select the autos to make the loading list",
        showConfirmButton: false,
        timer: 2000,
        toast: true,
      });
    }
  };

  const handleClose = () => {
    props.hideConfirmListModal();

  
  };
  const handleShow = () => props.showConfirmListModal();

  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
      baseUnit: 4,
    },
  });

  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const getListOfAutosByWarhouseses = (e) => {
    e.preventDefault();
    let warehouse = autoData.point_of_loading;
    props.getAutosByWarehouse(warehouse);
  };

  return (
    <>
     {/* ========functional Loader==========   */}

{
  props.functionalLoader?
<Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:''
}
      <div className="row pt-2 selector">
        <div className="col-md-3">
          <span>{t('key_select_autos_for_loading')}: </span>
        </div>
        <div className="col-md-2">
          <Select
            options={landingPointOptions}
            theme={theme}
            placeholder="Loading Point"
            name="point_of_loading"
            // isClearable={true}
            zIndex={1}
            menuPortalTarget={document.body}
            styles={menuPortal}
            defaultValue={PointLoadingDefaultValue}
            onChange={(e, selector) => handleChangeSelectors(e, selector)}
          />
        </div>
      </div>

      <div className="headerTable">
        <div className="row">
          <div className="col-md-12">
            <div className="cardTop">
              <div className="row details">
                <Table responsive borderless className="table-responsive res">
                  <thead className="tableHead">
                    <tr>
                      <th>{t('key_id')}</th>

                      <th>
          <Checkbox
        type="checkbox"
        name="selectAll"
        id="selectAll"
        handleClick={handleSelectAll}
        isChecked={isCheckAll}
      />                      </th>
                      {/* <th>Receiver Name</th> */}
                      <th>{t('key_photo')}</th>

                      <th>{t('key_date')}</th>

                      <th>{t('key_year')}</th>
                      <th>{t('key_make')}</th>
                      <th>{t('key_title_status')}</th>
                      <th>{t('key_delievered_loading_place')}</th>
                      <th>{t('key_port')}</th>
                      <th>VIN</th>
                      <th>LOT</th>
                      <th>{t('key_buyer_code')}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {props.loading? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />: props.autosDataByWarehouse?.data?.data?.map(
                          (list, index) => {
                            return (
                              <tr className="trBack" key={index}>
                                <td>
                                  <span>{list.real_auto_id}</span>
                                </td>
                                <td>
                                  <span>
                                    {/* <input
                                      type="checkbox"
                                      value={list.id}
          isChecked={isCheck.includes(list.id)}

                                      onChange={(event) => handleCheck(event, index)}
                                    /> */}

        <Checkbox
          key={list.real_auto_id}
          type="checkbox"
          name={list.real_auto_id}
          id={list.real_auto_id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.real_auto_id)}
        />
                                  </span>
                                </td>
                                {/* <td>
                                  <span>
                                    <input type="text"></input>
                                  </span>
                                </td> */}
                                <td>
                                  {props.autosDataByWarehouse?.images
                                    ?.filter((img) => img.autos_id === list.real_auto_id)
                                    ?.map((images, imgIndex) => {
                                      return (
                                        <span key={imgIndex}>
                                          <img
                                            alt="autos"
                                            src={`${process.env.REACT_APP_IMAGES_ADRESS}/${images.image_name}`}
                                          />
                                        </span>
                                      );
                                    })}
                                </td>
                                <td style={{ width: "100px" }}>
                                  <span>
                                    {moment(list.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span>{list.year}</span>
                                </td>
                                <td>
                                  <span>{list.make}</span>
                                </td>
                                <td style={{ width: "40px" }}>
                                  <span>{list.title_status}</span>
                                </td>
                                <td style={{ width: "100px" }}>
                                  <span>{list.delivered_warehouse}</span>
                                </td>
                                <td>
                                  <span>{list.port}</span>
                                </td>

                                <td>
                                  <span>{list.vin}</span>
                                </td>
                                <td>
                                  <span>{list.lot}</span>
                                </td>
                                <td>
                                  <span>{list.buyer_no}</span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      { can(currentUserPermissions,'Create Container')?<div className="row">
        <div className="paginate col-md-6">
          {isCheck.length > 0 ? (
            <button
              className="floating-btn-left"
              title="Register new loading list"
              onClick={() => {
                if (isCheck.length > 0) {
                  props.getSelectedAutosConfirm(isCheck);
                  setTimeout(() => {
                    props.showConfirmListModal();
                  }, 500);
                } else {
                  Swal.fire({
                    position: "top-end",
                    icon: "info",
                    text: "Please select the autos for creating the loading list",
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                  });
                }
              }}
            >
              {t('key_create_loading_lists')}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>:''}


      <div className="row pt-2 selector">
        <div className="col-md-3">
          <span>{t('key_select_consignee')} : </span>
        </div>
        <div className="col-md-4">
          <Select
            options={consigneeOptions}
            theme={theme}
            isClearable={true}
            placeholder="consignee"
            name="consignee_name"
            menuPortalTarget={document.body}
            styles={menuPortal}
            onChange={(e, selector) => handleChangeConsigneeSelector(e, selector)}

          />
        </div>
      </div>


      { props.autosDataByWarehouse?.data?.data.length===0 ? '':
<div>
      {props.autosDataByWarehouse?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6" dir="ltr">
            <Pagination
              activePage={props.autosDataByWarehouse?.data.current_page}
              itemsCountPerPage={props.autosDataByWarehouse?.data.per_page}
              totalItemsCount={props.autosDataByWarehouse?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) =>  props.getAutosByWarehouse(autoData.point_of_loading.value||'',pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}



      {/* ========================================================
Modal to confirm loading list
======================================================== */}

      <Modal show={props.openConfirmListModal} onHide={handleClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            {t('key_confirm_loading_list')}{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >
{autoData?.consignee_name?.value?<div style={{fontSize:'12px'}}><span style={{fontWeight:'600'}}>{t('key_only_consignee')}: </span><span>{autoData.consignee_name.value||''}</span></div>:<div><span style={{color:'red', fontSize:'14px'}}>{t('key_no_consignee_selected')}</span><br/></div>}
<br/>
<div className="card py-2 px-2 crtop">

<div className="row">
                  <div className="col-1 ht">{t('key_id')}</div>
                  <div className="col-md-1  ht">{t('key_photo')}</div>
                  <div className="col ht">{t('key_date')}</div>
                  <div className="col ht">{t('key_year')}</div>
                  <div className="col ht">{t('key_make')}</div>
                  <div className="col ht">{t('key_auction')}</div>
                  <div className="col-md-2 ht">{t('key_port')}</div>
                  <div className="col ht">VIN</div>
                  <div className="col ht">LOT</div>
                  <div className="col ht">{t('key_loading_port')}</div>


  </div>

</div>

          {props.selectedAutosConfirm?.data?.map((list, index) => {
            return (
              <div key={index}>
              <div className="pt-2"></div>
              <div className="card py-2 px-2">
                <div className="row">
                  <div className="col-1 t">{list.real_auto_id}</div>
                  <div className="col-md-1 col">

                  {props.selectedAutosConfirm?.images
                                    ?.filter((img) => img.autos_id === list.real_auto_id)
                                    ?.map((images, imgIndex) => {
                                      return (
                                        <span key={imgIndex}>
                                          <img
                                            alt="autos"
                                            src={`${process.env.REACT_APP_IMAGES_ADRESS}/${images.image_name}`}
                                          />
                                        </span>
                                      );
                                    })}

                  </div>
                  <div className="col t">
                    {moment(list.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                    </div>
                    <div className="col t">{list.year}</div>
                    <div className="col t ">{list.make}</div>
                    <div className="col t">{list.auction}</div>
                    <div className="col-md-2 t">{list.port}</div>
                    <div className="col t">{list.vin}</div>
                    <div className="col t">{list.lot}</div>
                    <div className="col t">{list.point_loading}</div>

                </div>
              </div>
              </div>
            );
          })}

          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button className="btn-cancel" onClick={handleClose}>
            {t('key_close')}
          </button>

          <button className="btn-submit" onClick={()=>{createLoadingList()}}>{t('key_confirm')}</button>
        </Modal.Footer>
      </Modal>





    </>
  );
}

const mapStateToProps = (state) => {
  const {preDataLoadResponse } = state.preDataHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  const {
    autosDataByWarehouse,
    loading,
    openConfirmListModal,
    selectedAutosConfirm,
    loadingListCreated,
    consigneePicker,
    consigneeRegistered,
    currentPage,
    functionalLoader

  } = state.LoadingListHandler;

  return {
    loading: loading,
    SelectorsFetch: SelectorsFetch,
    autosDataByWarehouse: autosDataByWarehouse,
    openConfirmListModal: openConfirmListModal,
    selectedAutosConfirm: selectedAutosConfirm,
    loadingListCreated:loadingListCreated,
    consigneePicker:consigneePicker,
    consigneeRegistered:consigneeRegistered,
    currentPage:currentPage,
    preDataLoadResponse:preDataLoadResponse,
    functionalLoader:functionalLoader,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmListModal: () => dispatch(showConfirmListModal()),
    hideConfirmListModal: () => dispatch(hideConfirmListModal()),
    consigneeSelector:()=>dispatch(consigneeSelector()),
    getSelectors: () => dispatch(getSelectors()),

    getAutosByWarehouse: (warehouse,pageNumber) =>
      dispatch(getAutosByWarehouse(warehouse,pageNumber)),
    makeLoadingList: (auto,consignee) => dispatch(makeLoadingList(auto,consignee)),
    getSelectedAutosConfirm: (autosIds) =>
      dispatch(getSelectedAutosConfirm(autosIds)),
      countLoadingLists:()=>dispatch(countLoadingLists()),
    
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoadingList);
