import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {billerUsersSelector,billerUpdate,hidebillerModal,showbillerModal,showEditbillerModal,hideEditbillerModal,getbillers,billerEdit,inputHandler,registerbiller, billerDelete} from "../../../redux/actions";
import { AsyncPaginate } from "react-select-async-paginate";

function Billers(props) {


// const [addbillerData,setAddbillerData] = useState({name:''});
const [billerData,setbillerData] = useState({name:'',onId:'',email:'',address:'',contact:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);
const [value, onChange] = useState([]);
const [editValue, setEditValue] = useState([]);


  useEffect(() => {
    props.getbillers(props.currentPage);
    if(props.registerbillerSuccess){
      onChange(null)
    }
  }, [props.registerbillerSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setbillerData({...billerData,
  'name':props.editDetails?.data?.biller_name||'',
  'contact':props.editDetails?.data?.biller_contact||'',
  'email':props.editDetails?.data?.biller_email||'',
  'address':props.editDetails?.data?.biller_address||''
})
setEditValue({label:props.editDetails?.data?.customerName, value:props.editDetails?.data?.userId})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setbillerData({...billerData, [e.target.name]:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hidebillerModal()

  };
  const handleShow = () => {

    props.showbillerModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditbillerModal();

  };

  const handleEditShow=()=>{
    props.showEditbillerModal()

  }





  const loadOptions = async (search, page) => {
    // await sleep(1000);
    const optionsPerPage = 6;

      const responseJSON = await props?.billerCustomersSelector?.data;
    // console.log(responseJSON.data)
    // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)

      let options; 
      if(responseJSON?.data){
          options = responseJSON.data.map(function (data) {
          return { value: data.id, label: `${data.name}` }
        })
      }
      // inshallah will iterate it from back end
  // console.log(options)
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
  
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = responseJSON.last_page > page;

    const slicedOptions = filteredOptions.slice(
      (page - 1) * optionsPerPage,
      page * optionsPerPage
    );
    // console.log(page)
    return {
      options: slicedOptions,
      hasMore
    };

  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    props.billerUsersSelector(q)

    const { options, hasMore } = await loadOptions(q, page);
  
    return {
      options,
      hasMore,
  
      additional: {
        page:  page + 1,
      }
    };
  };


  const defaultAdditional = {
    page: 1
  };
  
  




const deletebiller = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this biller",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.billerDelete(id, props.currentPage);
    }
  });
};





const submitbiller = (e) => {
  e.preventDefault();
  let name=props?.name;
  let email=props?.email;
  let address=props?.address;
  let contact = props?.contact;

  if(name){
    props.registerbiller(name,
      value?.value||"",
      email,
      address,
      contact
      )
  }
}


const Updatebiller = (e) => {
  e.preventDefault();
  let name=billerData.name;
  let contact=billerData.contact;
  let email=billerData.email;
  let address=billerData.address;


  let id=billerData.onId;
  let currentPage=props.billersList?.data?.current_page;
  props.billerUpdate(id,
    name,
    editValue?.value||"",
    currentPage,
    email,
    address,
    contact

  );
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7"> 
        
     <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add biller</a></div> 
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.billersList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-4 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.biller_name}</p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a onClick={
                    ()=>{
                      setbillerData({...billerData,'onId':list.id})
                      props.billerEdit(list.id)
                      setTimeout(()=>{     
                          handleEditShow() 
                      },190)
                            

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a  onClick={()=>deletebiller(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.billersList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.billersList?.data?.current_page}
              itemsCountPerPage={props.billersList?.data?.per_page}
              totalItemsCount={props.billersList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getbillers(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New biller </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitbiller}>
          <Modal.Body >
            <div className="row">

            <div className="col-md-12 mb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer to whom you want to add this biller
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={onChange}
        // closeMenuOnSelect={false}
        isClearable
      />


            </div>

              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.billerRegisterErrors?.error?.biller_name?true:false}
                  />

                </FloatingLabel>

                {props.billerRegisterErrors?.error?.biller_name ? (
                  <div className="validation-error">
                    The biller name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={props?.contact}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>


              </div>

              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={props?.email}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>



              </div>
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={props?.address}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>



              </div>






            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit biller </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={Updatebiller}>
          <Modal.Body >
            <div className="row">

            <div className="col-md-12 mb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer to whom you want to add this biller
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={editValue}
        loadOptions={loadPageOptions}
        onChange={setEditValue}
        // closeMenuOnSelect={false}
        isClearable
      />


            </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="biller Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={billerData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.billerUpdateErrors?.error?.biller_name ?true:false}
                  />

                </FloatingLabel>

                {props.billerUpdateErrors?.error?.biller_name ? (
                  <div className="validation-error">
                    The biller name already exists, you can't update it with this biller name !
                  </div>
                ) : (
                  ""
                )}

              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={billerData.contact||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>


              </div>

              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={billerData.email||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>


              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Biller Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={billerData.address||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>


              </div>



            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {billerCustomersSelector,loading,billersList,showWarModal,showEditWarModal,name,
    registerbillerSuccess,billerRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,billerUpdateErrors,
    email,
    contact,
    address,
  } = state.SettingsBillerHandler;

  return {
    loading: loading,
    name:name,
    email:email,
    contact:contact,
    address:address,
    billerRegisterErrors:billerRegisterErrors,
    currentPage:currentPage,
    billersList:billersList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerbillerSuccess:registerbillerSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    billerUpdateErrors:billerUpdateErrors,
    billerCustomersSelector:billerCustomersSelector
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
 
      getbillers:(currentPage)=>dispatch(getbillers(currentPage)),
      showbillerModal:()=>dispatch(showbillerModal()),
      hidebillerModal:()=>dispatch(hidebillerModal()),
      showEditbillerModal:()=>dispatch(showEditbillerModal()),
      hideEditbillerModal:()=>dispatch(hideEditbillerModal()),
      registerbiller:(name,id,email,address,contact)=>dispatch(registerbiller(name,id,email,address,contact)),
      billerEdit:(id)=>dispatch(billerEdit(id)),
      billerUpdate:(id,name,customerId,currentPage,contact,email,address)=>dispatch(billerUpdate(id,name,customerId,currentPage,contact,email,address)),
      billerDelete:(id,currentPage)=>dispatch(billerDelete(id,currentPage)),
      billerUsersSelector:(q)=>dispatch(billerUsersSelector(q)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Billers);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================