const InitialStates = {
    PortehousesList:null,
  
    currentPage: 1,
   
    showPortModal:false,
    showEditPortModal:false,
    loading:false,
    name:'',
  portsList:null,
    portRegisterErrors:null,
    portUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showPortModal":
      return { ...state, 'showWarModal':true };

      case "hidePortModal":
        return { ...state, 'showWarModal':false, 'PortRegisterErrors':'', 'name':'' };
  

        case "showEditPortModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditPortModal":
            return { ...state, 'showEditWarModal':false, 'PortUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================
            case "getPort_started":
              return {...state, 'loading':true}
    case "registerPort_started":
              return {...state, 'loading':true}

              // case "PortEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "PortUpdate_started":
              //     return {...state, 'loading':true}
// ==================ends here=====================
       case "getPortsSettings_success":

         return { ...state, 'PortsList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerPort_success":
    return { ...state, 'registerPortSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "PortEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "PortUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "PortDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerPort_failure":
    return { ...state, 'PortRegisterErrors': action.payload,  'loading':false };

case "PortUpdate_failure":
  return { ...state, 'PortUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   