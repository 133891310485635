


import React, { useState,useEffect } from "react";
import "../../../css/loginStyle.css";
import Bg from "../../assets/bg-login.jpg";
import Logo from "../../assets/GA-01.png";
import LogoNoName from "../../assets/logoNoName.png";
import Carousel from 'react-bootstrap/Carousel';
import moment from 'moment';
import BackgroundLogo from "../../assets/logoNoName.png";
import { connect } from "react-redux";
import {
   LoginAuth,
   showAndHidePublicTrackerModal,
} from "../../../redux/actions";
import Loader from "../../elements/Loader";
import {useNavigate,Outlet,NavLink} from 'react-router-dom';
import "../../../css/LoginLoader.css"
import _ from 'lodash';
import { useTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown';
import { CircleFlag } from 'react-circle-flags'
import i18next from 'i18next'
import cookies from 'js-cookie'
import CloseButton from "react-bootstrap/esm/CloseButton";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

var CryptoJS = require("crypto-js");



function Login(props) {

  

  const navigate = useNavigate();







  

  const languages = [
  
    {
      code: 'en',
      name: 'English',
      country_code: 'us',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ]


  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation() 




  useEffect(() => {
    // console.log('Setting page stuff')
    // document.body.dir = currentLanguage.dir || 'ltr'
    let directionOfLanguage= currentLanguage.dir || 'ltr';
 
  
    const el = document.querySelectorAll('.langDirection')
    // console.log(el)
    if(el!=null){
    var div_array = [...el]; 
    div_array.forEach(div_array => {

     div_array.setAttribute('dir',directionOfLanguage.toString());
     localStorage.setItem('lang_dir', JSON.stringify(directionOfLanguage));

      });
    }
    // if(el!=null){
    //   document.querySelector('.langDirection').dir=directionOfLanguage.toString();
    //   localStorage.setItem('lang_dir', JSON.stringify(directionOfLanguage));

    // }

    // console.log(el)

    document.title = t('app_title')
  }, [currentLanguage, t])





  useEffect(() => {

    if (props.isAuthenticated) {
      navigate("/home/autos");
    }
  }, [props.isAuthenticated]);

   




//this is authenticated after login.


  return (
    <>





      <div className="body-color">
     

      <div className="shade-background"></div>
 
        <section className="ftco-section" style={{zIndex:'999'}}>
          <div className="container">
       
          <div className="img-logo-background-container">
                  <img src={Logo} alt="logo" className="img-logo-background"  style={{overflow:'hidden'}}></img>
                </div>

         
     

            <div className="row justify-content-center">
              <div className="col-md-6 text-center mb-5">
                <div className="p-0">
                  <img src={Logo} alt="logo" className="img-logo"></img>
                </div>
              </div>

            </div>
            <div className="row justify-content-center ">
              <div className="col-sm-10 col-md-8 col-lg-5">
              <div className="login-form-outer-container">
              <div className="login-buttons-container btn-group d-flex">
              <NavLink to=""   className={({ isActive }) => (isActive ? 'login-button w-100 activeLoginButton langDirection' : 'login-button w-100 langDirection')} dir={JSON.parse(localStorage.getItem('lang_dir'))}><span className="fa fa-sign-in"></span> <span className="">{t('key_login')}</span></NavLink>
							<NavLink to="TrackVehicle" className={({ isActive }) => (isActive ? 'track-vehicle-button w-100 activeLoginButton langDirection' : 'track-vehicle-button w-100 langDirection')} dir={JSON.parse(localStorage.getItem('lang_dir'))}><span className="fa fa-car"></span><span className="">{t('key_track')}</span></NavLink>
              </div>
             
                <div className="wrap">

              {/* <div
                    className="img"
                    style={{ backgroundImage: `url(${Bg})` }}
                  ></div> */}

                 

<Outlet/>



                </div>
                                 {/* wrap ends here */}

                 </div>
                 {/* outer layer container ends here */}
              </div>
            </div>
          </div>














          <div style={{position:'fixed',left:'4px', bottom:'10px', zIndex:'99999'}}>
<Dropdown drop={'up'}>
      <Dropdown.Toggle variant="success" id="dropdown-basic"  >
      <i className="fas fa-globe-europe"></i>
            </Dropdown.Toggle>

      <Dropdown.Menu>
      {languages.map((list,index)=>{
        return (
          <Dropdown.Item  key={index} onClick={ ()=>i18next.changeLanguage(list.code)}   className={currentLanguageCode===list.code? 'active':''} >
          <span ><CircleFlag countryCode={list.country_code} height="20" /></span>

         <span > {list.name} </span>
          </Dropdown.Item>

        )
      })}
    
      </Dropdown.Menu>
    </Dropdown>
    </div>

    <div  className="text-center" style={{position:'relative', top:'10px', textAlign:'center',fontSize:'11px',color:'white',zIndex:'4'}}>
Copy Right &copy; <a href={process.env.REACT_APP_ALL_RIGHTS_RESERVED_LINK} target="_blank"> <span style={{color:'#ff9900'}}>{process.env.REACT_APP_NAME}</span> </a>	 All Rights Reserved <br/>

Designed & Developed by <span><a href={process.env.REACT_APP_MY_WEBSITE} target="_blank" style={{textDecoration:'none',color:'#ff9900'}}>Sabaoon Bedar</a>	</span>
</div>
        </section>



      </div>










{/* 
========================================================
Modal of editing notification
======================================================== 
*/}

<Modal show={props.showAndHideAutoPublicModal} className="tracker-modal" size="fullscreen" style={{overflow:'hidden'}} onHide={()=>{props.showAndHidePublicTrackerModal()}}>
<div   className="circle modal-cross"  onClick={()=>props.showAndHidePublicTrackerModal()}> <i className="fas fa-times" style={{color:'white',zIndex:'99'}}></i></div>
<Modal.Body style={{ 
   overflowY: 'auto',
}}>
     <div className="modal-shade"></div>
     <div className="modal-img">
                  <img src={Logo} alt="logo" ></img>
    </div>

<div className="modal-container">

<div className="carousel-container">
<div className="row no-gutters">
{
  props.VehicleTrackersPublicSearchSuccess?.images.length !=0?
  <div className="col-md-6 ">
  <Carousel className="carousel-height">


  { props.VehicleTrackersPublicSearchSuccess?.images?.map((img,index)=>{

return (


  
  <Carousel.Item interval={1500} key={index}>

        <img  className="d-block w-100" src={`${process.env.REACT_APP_IMAGES_ADRESS}/${img.image_name}`} />
        
        <Carousel.Caption>
          <h3>{props.VehicleTrackersPublicSearchSuccess?.data?.vin}</h3>
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
  


)



  })
     
  
  }


      
    </Carousel>

  </div>:
  <div className="col-md-6 d-flex justify-content-center align-items-center">
  <p>No images of the vehicle are uploaded by the adminstration </p>
</div>
}

  <div className="col-md-6" >
<div className="auto-main-detail-card">
{props.VehicleTrackersPublicSearchSuccess?.data?.year} {props.VehicleTrackersPublicSearchSuccess?.data?.make} {props.VehicleTrackersPublicSearchSuccess?.data?.model} 
 
</div>




<div className="pt-4 y-gap"></div>
<div className="main-details langDirection">


<div className="auto-general-details-card badge-badge-primary" style={{background:'black'}} >
<div>{t('key_owner')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.for_customer}</div>
</div>
<div className="pt-4 y-gap"></div>



<div className="auto-general-details-card" >
<div>{t('key_vin')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.vin}</div>
</div>




<div className="pt-1 y-gap"></div>

<div className="auto-general-details-card">
<div>{t('key_lot')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.lot}</div>
</div>

<div className="pt-1 y-gap"></div>
<div className="auto-general-details-card">
<div>{t('key_container_no')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.container_num_booking}</div>
</div>

<div className="pt-1 y-gap"></div>

<div className="auto-general-details-card">
<div>{t('key_buyer_no')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.buyer_no}</div>
</div>
<div className="pt-1 y-gap"></div>

<div className="auto-general-details-card">
<div>{t('key_color')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.color}</div>
</div>
    <div className="pt-1 y-gap"></div>

<div className="auto-general-details-card">

<div>{t('key_keys')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.keys}</div>
</div>

<div className="pt-1 y-gap"></div>

    <div className="auto-general-details-card">

<div>{t('key_auto_type')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.auto_type}</div>
</div>
    

</div>
</div>


</div>
  

  </div>
  <div className="pt-4"></div>
<div className="row" >
  <div className="col-md-12 land">
<div className="auto-main-detail-card-2 d-flex d-inline-flex" >
<span>{t('key_vehicle_details')}</span>
</div>



  <div className="extra-details-container">

<div className="pt-2"></div>
  <div className="car-extra-details-container langDirection">

<div className="card-data">
<div className="row">
  <div className="col-md-6 ">
  <div className="pt-1"></div>

<div className="extra-info-card">
<div>{t('key_date')} </div>: <div>{moment(props.VehicleTrackersPublicSearchSuccess?.data?.created_at).format('DD-MM-YYYY')}</div>
</div>
  <div className="pt-1"></div>

<div className="extra-info-card">
<div>{t('key_auction')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.auction}</div>
</div>
<div className="pt-1"></div>
<div className="extra-info-card">
<div>{t('key_city')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.city}</div>
</div>

<div className="pt-1"></div>
<div className="extra-info-card">
<div>{t('key_point_of_loading')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.point_loading}</div>
</div>


<div className="pt-1"></div>
<div className="extra-info-card">
<div>{t('key_destination_port')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.port}</div>
</div>

<div className="pt-1"></div>


  </div>

  <div className="col-md-6 ">
  <div className="pt-1"></div>

 

<div className="pt-1"></div>

  <div className="extra-info-card">
<div>{t('key_purchase_date')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.purchase_date?moment(props.VehicleTrackersPublicSearchSuccess?.data?.purchase_date).format('DD-MM-YYYY'):''}</div>
</div>

<div className="pt-1"></div>

  <div className="extra-info-card">
<div>{t('key_promised_pick_up')} </div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.promise_pickup?moment(props.VehicleTrackersPublicSearchSuccess?.data?.promise_pickup).format('DD-MM-YYYY'):''}</div>
</div>

<div className="pt-1"></div>

  <div className="extra-info-card">
<div>{t('key_delievered_to_warehouse')}</div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.delivered_warehouse?moment(props.VehicleTrackersPublicSearchSuccess?.data?.delivered_warehouse).format('DD-MM-YYYY'):''}</div>
</div>


<div className="pt-1"></div>

  <div className="extra-info-card">
<div>{t('key_arrival_date')}</div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.arrival_date?moment(props.VehicleTrackersPublicSearchSuccess?.data?.arrival_date).format('DD-MM-YYYY'):''}</div>
</div>
  <div className="pt-1"></div>

<div className="extra-info-card">
<div>{t('key_unloading_date')}</div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.unloading_date?moment(props.VehicleTrackersPublicSearchSuccess?.data?.unloading_date).format('DD-MM-YYYY'):''}</div>
</div>



  <div className="pt-1"></div>

  </div>






</div>

  
</div>
</div>

  </div>






  <div className="extra-details-container ">

<div className="pt-2"></div>
  <div className="car-extra-details-container langDirection">

<div className="card-data">
<div className="row">
  <div className="col-md-12 ">
  {/* <div className="pt-1"></div> */}

  <div className="extra-info-card">
<div>{t('key_title')}</div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.title_status}</div>
</div>
  <div className="pt-1"></div>

<div className="extra-info-card">
<div>{t('key_notes')}</div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.notes}</div>
</div>
<div className="pt-1"></div>
<div className="extra-info-card">
<div>{t('key_terminal_notes')}</div>: <div>{props.VehicleTrackersPublicSearchSuccess?.data?.terminal_notes}</div>
</div>



<div className="pt-1"></div>


  </div>







</div>

  
</div>
</div>

  </div>







</div>
</div>



</div>
     
         
         
</Modal.Body>
    
      </Modal>







    </>
  );
}

const mapStateToProps = (state) => {
  const {loginResponse, isAuthenticated } = state.authenticationHandler;
  const {loading, showAndHideAutoPublicModal,VehicleTrackersPublicSearchSuccess} = state.VehicleTrackerHandler

  return {
    loading:loading,
    loginResponse:loginResponse,
    isAuthenticated:isAuthenticated,
    showAndHideAutoPublicModal:showAndHideAutoPublicModal,
    VehicleTrackersPublicSearchSuccess:VehicleTrackersPublicSearchSuccess,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) =>
      dispatch(LoginAuth(credentials)),
      showAndHidePublicTrackerModal:()=>dispatch(showAndHidePublicTrackerModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
