import {getAutoAdditionalDetails} from "../../../redux/actions";
import { connect } from "react-redux";
import Loader from "../../elements/Loader";
import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import ImagesCarousel from '../autos/ViewAutoCarousel';
import moment from "moment";
import {downloadImagesZip} from '../../../redux/actions';
import { useTranslation } from 'react-i18next'
import {can} from '../../utils/roleUtils';
function ViewAuto(props) {
  const [id, setId] = useState('');
  const params = useParams();
  const {t}=useTranslation();


useEffect(()=>{
 let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
 let decryptValue=_ciphertext.toString(CryptoENC);
if(decryptValue){
  setId(decryptValue);
props.getAutoAdditionalDetails(decryptValue);
}
},[])

// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


  return (
    <div>
<div className="container-fluid" style={{paddingLeft:'0px',overflowX:'hidden'}}>


<div className="row">
<div className="col">
<div className="p-2 cardHeader">
<h6>{props.getAdditionalRegDataSuccess?.data?.year} {props.getAdditionalRegDataSuccess?.data?.make} {props.getAdditionalRegDataSuccess?.data?.model}  </h6>
<div className="row">
<div className="col-md-3"><span className="sub-header">LOT: {props.getAdditionalRegDataSuccess?.data?.lot}</span></div>
<div className="col-md-3"><span className="sub-header">VIN: {props.getAdditionalRegDataSuccess?.data?.vin}</span></div>
</div>
</div>
</div>

</div>

<div className="pt-3">

<div className='row' style={{paddingLeft:'30px'}}>
<div className="col-md-8">
{props.getAdditionalRegDataSuccess?.images.length>0?
<>
{can(currentUserPermissions,'Download Autos Images')?<div className="downloadImages"><a onClick={()=>props.downloadImagesZip(id)}><i class="fas fa-download"></i> Download Images</a></div>:""}
</>
:''}


<ImagesCarousel autoImages={props.getAdditionalRegDataSuccess?.images}/>

</div>
<div className="col-md-4">
<div className="card side-card border-0">

<Table responsive="md"  striped={true} className="langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
    <thead>
      <tr>
        
        <th colSpan="2"> {t('key_auto_details')} <span><i class="fas fa-car-side"></i></span> </th>
      
     
      </tr>
    </thead>
    <tbody>

 
 
    <tr>
    <td>Vin:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.vin}</td>
    </tr>
    
    <tr>
    <td>Lot:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.lot}</td>
    </tr>

    <tr>
    <td>{t('key_auction')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.auction}</td>
    </tr>

    <tr>
    <td>{t('key_buyer_no')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.buyer_no}</td>
    </tr>

    <tr>
    <td>{t('key_color')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.color}</td>
    </tr>
    
    <tr>
    <td>{t('key_keys')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.keys=='true'? 'Yes':'No'}</td>
    </tr>

    <tr>
    <td>{t('key_point_of_loading')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.point_loading}</td>
    </tr>
    <tr>
    <td>{t('key_city')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.city}</td>
    </tr>
    <tr>
    <td>{t('key_destination_port')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.port}</td>
    </tr>
    
    <tr>
    <td>{t('key_container_no')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.container_num_booking}</td>
    </tr>
     
    </tbody>
  </Table>


</div>

<br/>

<div className="card side-card border-0">

<Table responsive="md"  striped={true}  className="langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
    <thead>
      <tr>
        
        <th colSpan="2">{t('key_more_info')} <span><i class="fas fa-truck-moving"></i></span> </th>
      
     
      </tr>
    </thead>
    <tbody >

 
 
   
    
    <tr >
    <td>{t('key_auto_type')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.auto_type}</td>
    </tr>
    <tr>
    <td>{t('key_date')}:</td>
    <td>{moment(props.getAdditionalRegDataSuccess?.data?.created_at).format('YYYY-MM-DD')}</td>
    </tr>

    <tr>
    <td>{t('key_purchase_date')}:</td>
    <td>{moment(props.getAdditionalRegDataSuccess?.data?.purchase_date).format('YYYY-MM-DD')}</td>
    </tr>

    <tr>
    <td>{t('key_promised_pick_up')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.promise_pickup}</td>
    </tr>

    <tr>
    <td>{t('key_delievered_to_warehouse')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.delivered_warehouse}</td>
    </tr>

    <tr>
    <td>{t('key_arrival_date')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.arrival_date}</td>
    </tr>

    <tr>
    <td>{t('key_unloading_date')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.unloading_date}</td>
    </tr>
     
    </tbody>
  </Table>


</div>



</div>


<div className="col-md-12">
<br/>
<div className="card side-card border-0">

<Table responsive="md"  className="langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))} >
    <thead>
      <tr>
        
        <th colSpan="2">Other Information <span><i class="fas fa-clipboard"></i></span> </th>
      
     
      </tr>
    </thead>
    <tbody>

 
 
    <tr>
    <td>{t('key_title')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.title_status=="true"? 'Yes':'No'}</td>
    </tr>
    <tr>
    <td>{t('key_notes')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.customer_notes}</td>
    </tr>
    <tr>
    <td>{t('key_terminal_notes')}:</td>
    <td>{props.getAdditionalRegDataSuccess?.data?.terminal_notes}</td>
    </tr>
    
     
    </tbody>
  </Table>
  </div>

</div>






</div>

</div>
</div>


        
    </div>
  )
}



const mapStateToProps = (state) => {

    const {loading, getAdditionalRegDataSuccess } = state.AutosHandler;
  
    const { preDataLoadResponse } = state.preDataHandler;

    return {
    
      loading:loading,
      preDataLoadResponse:preDataLoadResponse,
     
      getAdditionalRegDataSuccess:getAdditionalRegDataSuccess,

     
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
  
      getAutoAdditionalDetails:(id)=>dispatch(getAutoAdditionalDetails(id)),
      downloadImagesZip:(id)=>dispatch(downloadImagesZip(id)),
    };
  };



export default connect(mapStateToProps,mapDispatchToProps)(ViewAuto)