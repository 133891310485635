import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");


// ---------------------------------------------------------------------------------------------------------
// searching data for users
// ---------------------------------------------------------------------------------------------------------

export const searchUsersList = (query,pageNumber) => {

    return (dispatch) => {
      dispatch(searchUsersStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };

      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchUsers?page=${pageNumber}`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(searchUsersSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchUsersFailure(err.message));
        });
    };
  };
  
  const searchUsersStarted = () => ({
    type: "searchUsers_started",
  });
  
  const searchUsersSuccess = (data) => ({
    type: "searchUsers_success",
    payload: {
      ...data,
    },
  });
  
  const searchUsersFailure = (error) => ({
    type: "searchUsers_failure",
    payload: {
      error,
    },
  });
  


// ---------------------------------------------------------------------------------------------------------
// searching data of Autos
// ---------------------------------------------------------------------------------------------------------

export const searchAutosList = (query,pageNumber) => {

  return (dispatch) => {
    dispatch(searchAutosStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchAutos?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchAutosSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchAutosFailure(err.message));
      });
  };
};

const searchAutosStarted = () => ({
  type: "searchAutos_started",
});

const searchAutosSuccess = (data) => ({
  type: "searchAutos_success",
  payload: {
    ...data,
  },
});

const searchAutosFailure = (error) => ({
  type: "searchAutos_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// searching data of Containers
// ---------------------------------------------------------------------------------------------------------

export const searchContainersList = (query,pageNumber) => {

  return (dispatch) => {
    dispatch(searchContainerStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchContainers?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchContainerSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchContainerFailure(err.message));
      });
  };
};

const searchContainerStarted = () => ({
  type: "searchContainer_started",
});

const searchContainerSuccess = (data) => ({
  type: "searchContainer_success",
  payload: {
    ...data,
  },
});

const searchContainerFailure = (error) => ({
  type: "searchContainer_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// searching data for users
// ---------------------------------------------------------------------------------------------------------

export const searchNotificationList = (query,currentPage) => {
// console.log(query)
  return (dispatch) => {
    dispatch(searchNotificationStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchNotification?page=${currentPage}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchNotificationSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchNotificationFailure(err.message));
      });
  };
};

const searchNotificationStarted = () => ({
  type: "searchNotification_started",
});

const searchNotificationSuccess = (data) => ({
  type: "searchNotification_success",
  payload: {
    ...data,
  },
});

const searchNotificationFailure = (error) => ({
  type: "searchNotification_failure",
  payload: {
    error,
  },
});






