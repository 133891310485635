import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import _ from 'lodash'
import Loader from "../../elements/Loader"
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Pagination from "react-js-pagination";
import ListCommunication from "./ListCommunication";
import EditLoadingList from '../LoadingLists/EditLoadingList';
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

import {
  switchLoadingList,
  confirmOrRejectList,
  countLoadingLists,
  loadingListDelete,
  showEditLoadingModal,
  hideEditLoadingModal,
  autoDeleteFromLoadingList,
  showLoadingCommunicationModal,
  hideLoadingCommunicationModal,
  showAddContianerNoModal,
  hideAddContianerNoModal,
  addContainerNumber,
  editContainerInfo,
  getLoadingListCommunication
} from "../../../redux/actions";
import {useParams } from "react-router-dom";
import {can,hasAnyRole} from '../../utils/roleUtils';


function LoadedLists(props) {


const [loadingListId, setLoadingListId] = useState({autoId:'', loadingListId:'',container_no:''})
const [previousConsignee, setPreviousConsignee] = useState(null)


const handleClose = () => {
  props.hideConfirmListModal();
};

const handleLoadingCommunicationClose=()=>{
props.hideLoadingCommunicationModal()
}

const handleEditClose = () => {
  props.hideEditLoadingModal();
};

const handleAddContainerModalClose=()=>{

  props.hideAddContianerNoModal();

}

const onInputChange=(e)=>{

  setLoadingListId({ ...loadingListId, [e.target.name]:e.target.value });

}

const addContainerNumber=(e)=>{
  e.preventDefault();
props.addContainerNumber(loadingListId.loadingListId,loadingListId.container_no);
}
  const params = useParams();

useEffect(()=>{
let mode = params.mode;
props.switchLoadingList(mode,props.currentPage);
props.countLoadingLists();
},[props.confirmedOrRejectStatus,props.loadingListdeleted,props.deleteAutoFromLoadingListS,props.addAutosToListEdit,props.deletedSelectedNotes,props.addContNo])

useEffect(()=>{
  setLoadingListId({...loadingListId,'container_no':props.containerNoInfo?.data?.container_no})
},[props.containerNoInfo])



const deleteLoadingList=(id)=>{
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this loading list",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.loadingListDelete(id);
    }
  });

}

const {t}=useTranslation();

const deleteAutoFromList=(model,make,autoId,listId)=>{

  Swal.fire({
    title: "Are you sure?",
    text: `You want to delete this ${make} ${model} from the loading list`,
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.autoDeleteFromLoadingList(autoId,listId)   
     }
  });


}


// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



  return (
    <div>



{
  props.functionalLoader?
<Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:''
}



<div className="headerTable">
        <div className="row">
          <div className="col-md-12">
            <div className="cardTop">
              <div className="row details">
                <Table responsive borderless className="table-responsive res">
                  <thead className="tableHead">
                    <tr>
                      <th>{t('key_id')}</th>
                      <th>{t('key_plan_created')}</th>
                      <th>{t('key_only_consignee')}</th>
                      <th>{t('key_autos')}</th>
                      <th>{t('key_loading_port')}</th>

                      <th>{t('key_loading_list_notes')}</th>
                      <th>{t('key_container_no')}</th>

                      {hasAnyRole(props.preDataLoadResponse?.currentUserRole,process.env.REACT_APP_SUPER_ADMIN,process.env.REACT_APP_ADMIN)  ?
     <th>Actions</th>:null}
                     
                    
                    </tr>
                  </thead>

                  <tbody>
                  { props.loading? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />: props.catagoryLists?.catagoryList.data?.map((list,index)=>{
                    return(

                      <tr className="trBack" key={index}>
                                <td>
                                  <span>{list.id}</span>
                                </td>
                                <td>
                                  <span>{moment(list.created_at).format(
                                      "DD-MM-YYYY"
                                    )}</span>
                                </td>

                                <td style={{width:'240px',position:'relative'}}>
                                  <span>
                         {list.consignee_name}
                                  </span>

                        {hasAnyRole(props.preDataLoadResponse?.currentUserRole,process.env.REACT_APP_SUPER_ADMIN,process.env.REACT_APP_ADMIN)  ?  <span className="createdByReady" style={{fontSize:'11px',fontWeight:600}}>Created by: {list.username}</span>:''}

                                </td>
                               
                                <td style={{width:'400px'}}>
                        
                           {/* note that this is for autos */}


                                {props.catagoryLists?.list?.filter(item=>item.listsRealID===list.id).map((l,i)=>{
                                  return(
                               <div style={{position:'relative'}} key={i}>
                                    <div  className="card" >
                                    <span style={{color:'#084896', fontWeight:'600'}}>{l.year} {l.make} {l.model} </span> 
                            <span>
                                      <span style={{fontWeight:'550'}}>
                                    LOT:  &nbsp;
                                      </span>
                                    <span>{l.lot}  </span> 

                                    <span style={{fontWeight:'550'}}>
                                    VIN:  &nbsp;
                                    </span>
                                    <span>
                                        {l.vin}
                                    </span>
                         </span>
                             </div>
                             <div style={{height:'6px'}}></div>
                             {hasAnyRole(props.preDataLoadResponse?.currentUserRole,process.env.REACT_APP_SUPER_ADMIN,process.env.REACT_APP_ADMIN)  ?
                              <> { can(currentUserPermissions,'Delete Vehicle in Loading List')?<div className="delete-icon-autos">

                              <i className="fas fa-trash" onClick={()=>deleteAutoFromList(l.model, l.make,l.autoId,list.id)}></i>


                        </div>:""}</>:''}
                      
                       
                         </div>
                                  )
                                })
                                 
                                }

                              {/* note that this is for autos */}
                                </td>
                                <td>
                                {_.uniqBy(props.catagoryLists?.list?.filter(item=>item.listsRealID===list.id),'point_loading').map((l,i)=>{

                                  return (
                                  <div key={i} style={{fontSize:'12px'}}>{l.point_loading}</div> 
                                  )

                                })
                                  
                              
                                }
                               
                                </td>
                                <td>
                                {can(currentUserPermissions,'View Loading List Notes')?  <span> 
                                  <div style={{height:'12px'}}></div>
                                  <button className="notification" onClick={()=>{
                                   setLoadingListId({...loadingListId,loadingListId:list.id})
                                   props.getLoadingListCommunication(list.id)
                                   setTimeout(()=>{
                                    props.showLoadingCommunicationModal()

                                   },200)
                                    }}>
                                    
                                    <span>{t('key_view_notes')}</span>
                                 { props.catagoryLists?.notesCount.filter(item=>item.lists_id===list.id).map((l,i)=>{return(
                                  <span key={i} className="badge">{l.totalMessages}</span>
                                 )}) }
                                    
                                    
                                    
                                    </button></span>:''}
                                </td>

                                <td><span>Booked for loading</span></td>



                                {hasAnyRole(props.preDataLoadResponse?.currentUserRole,process.env.REACT_APP_SUPER_ADMIN,process.env.REACT_APP_ADMIN)  ?
                               <td style={{width:'110px'}}>
                            

                              {can(currentUserPermissions,'Edit Container number in Loading List')?<div>
                               <button className="btn-container" style={{whiteSpace:'nowrap'}} onClick={()=>{
                                props.editContainerInfo(list.id);
                               setLoadingListId({...loadingListId,loadingListId:list.id})
                                props.showAddContianerNoModal()
                                }} >Container No</button>
                               
                               </div>:""}


                             {can(currentUserPermissions,'Edit Loading List')? <div> <button className="btn-edit-loading" onClick={()=>{
                                setLoadingListId({...loadingListId,loadingListId:list.id})
                                if(list.consignee_name==null){
                                  setPreviousConsignee(null)

                                }else{                          
                                        setPreviousConsignee({'label':list.consignee_name,'value':list.consignee_name})

                                }
                                props.showEditLoadingModal()
                                
                                }}>Edit List</button></div>:"" }
                        
                             {can(currentUserPermissions,'Confirm Loading List')? <div> 
                              <button className="btn-confirm-loading" onClick={()=>props.confirmOrRejectList('ready',list.id)}
                              >Confirm List</button>
                              </div>:'' }
                             
                              {can(currentUserPermissions,'Reject Loading List')? <div>
                               <button className="btn-reject-loading" onClick={()=>props.confirmOrRejectList('rejected',list.id)}>Reject List</button>
                               
                               </div>:""
                               }
                           
                          { can(currentUserPermissions,'Delete Loading List')?  <div>
                             <button className="btn-delete-loading" onClick={()=>deleteLoadingList(list.id)}>Delete List</button>
                             
                             </div>:'' }

                     

                                </td>
                            
                                :''}


                              </tr>

                           
                        
                    )
                  })
                    
                  }
                        
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>



      { props.catagoryLists?.catagoryList?.data.length===0 ? '':
<div>
      {props.catagoryLists?.catagoryList?.data ? (
        <div className="row">
          <div className="paginate col-md-6" dir="ltr">
            <Pagination
              activePage={props.catagoryLists?.catagoryList?.current_page}
              itemsCountPerPage={props.catagoryLists?.catagoryList?.per_page}
              totalItemsCount={props.catagoryLists?.catagoryList?.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) =>  props.switchLoadingList(params.mode,pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}


 {/* ========================================================
Modal to edit loading lists
======================================================== */}

<Modal show={props.openEditLoadListModal} onHide={handleEditClose} size="fullscreen">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
          {t('key_edit_loading_list')}{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >


<EditLoadingList
selectedConsignee = {previousConsignee}
loadingListId={loadingListId.loadingListId}
/>


          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button className="btn-cancel" onClick={handleEditClose}>
            Close
          </button>

          {/* <button className="btn-submit" >Confirm</button> */}
        </Modal.Footer>
      </Modal>





{/* ========================================================
communication modal
======================================================== */}

<Modal show={props.loadingListCommunication} onHide={handleLoadingCommunicationClose} size="fullscreen">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
           {t('key_loading_list_communication')}{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleLoadingCommunicationClose}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >


<ListCommunication
loadingListId={loadingListId.loadingListId}
currentPage={props?.currentPage}
mode={params.mode}
/>

          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
        <div style={{height:'46px'}}></div>
          {/* <button className="btn-cancel" onClick={handleLoadingCommunicationClose}>
            Close
          </button> */}

          {/* <button className="btn-submit" >Confirm</button> */}
        </Modal.Footer>
      </Modal>


{/* ========================================================
Modal to add container no
======================================================== */}

<Modal show={props.openContainerNo} onHide={handleAddContainerModalClose} size="l">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Add Container No{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleAddContainerModalClose}></CloseButton>
        </Modal.Header>
        <form onSubmit={addContainerNumber}>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >
<div className="col-md-12 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="container_no"
                    type="input"
                    name="container_no"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}

                    value={loadingListId.container_no||''}
                    required
                  />
                  <label htmlFor="model">Container No</label>
                </Form.Floating>
              </div>

        </Modal.Body>

        <Modal.Footer className="modal-footer">
          {/* <button className="btn-cancel" onClick={handleAddContainerModalClose}>
            Close
          </button> */}

          <button className="btn-submit">Add</button>
        </Modal.Footer>

</form>

      </Modal>




    </div>
  )
}





const mapStateToProps = (state) => {

  const {preDataLoadResponse } = state.preDataHandler;

  const {
    loading,
    catagoryLists,
    confirmedOrRejectStatus,
    loadingListdeleted,
    hideEditLoadListModal,
    openEditLoadListModal,
    currentPage,
    deleteAutoFromLoadingListS,
    addAutosToListEdit,
    loadingListCommunication,
    deletedSelectedNotes,
    openContainerNo,
    addContNo,
    containerNoInfo,
    functionalLoader
  } = state.LoadingListHandler;


  return {
    loading: loading,
    deletedSelectedNotes:deletedSelectedNotes,
    catagoryLists:catagoryLists,
    confirmedOrRejectStatus:confirmedOrRejectStatus,
    loadingListdeleted:loadingListdeleted,
    openEditLoadListModal:openEditLoadListModal,
    currentPage:currentPage,
    deleteAutoFromLoadingListS:deleteAutoFromLoadingListS,
    addAutosToListEdit:addAutosToListEdit,
    loadingListCommunication:loadingListCommunication,
    preDataLoadResponse:preDataLoadResponse,
    openContainerNo:openContainerNo,
    addContNo:addContNo,
    containerNoInfo:containerNoInfo,
    functionalLoader:functionalLoader
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    showEditLoadingModal: () => dispatch(showEditLoadingModal()),
    hideEditLoadingModal: () => dispatch(hideEditLoadingModal()),
    showAddContianerNoModal: () => dispatch(showAddContianerNoModal()),
    hideAddContianerNoModal: () => dispatch(hideAddContianerNoModal()),
    showLoadingCommunicationModal: () => dispatch(showLoadingCommunicationModal()),
    hideLoadingCommunicationModal: () => dispatch(hideLoadingCommunicationModal()),
    confirmOrRejectList:(status,id)=>dispatch(confirmOrRejectList(status,id)),
    switchLoadingList: (catagory,pageNumber) => dispatch(switchLoadingList(catagory,pageNumber)),
    countLoadingLists:()=>dispatch(countLoadingLists()),
    loadingListDelete:(id)=>dispatch(loadingListDelete(id)),
    autoDeleteFromLoadingList:(autoId,listId)=>dispatch(autoDeleteFromLoadingList(autoId,listId)),
    addContainerNumber:(loadingListId,container_no)=>dispatch(addContainerNumber(loadingListId,container_no)),
    editContainerInfo:(loadingListId)=>dispatch(editContainerInfo(loadingListId)),
    getLoadingListCommunication:(conId)=>dispatch(getLoadingListCommunication(conId))
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(LoadedLists)