const InitialStates = {
    consigneeehousesList:null,
  
    currentPage: 1,
   
    showconsigneeModal:false,
    showEditconsigneeModal:false,
    loading:false,
    name:'',
  consigneesList:null,
    consigneeRegisterErrors:null,
    consigneeUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showconsigneeModal":
      return { ...state, 'showWarModal':true };

      case "hideconsigneeModal":
        return { ...state, 'showWarModal':false, 'consigneeRegisterErrors':'', 'name':'' };
  

        case "showEditconsigneeModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditconsigneeModal":
            return { ...state, 'showEditWarModal':false, 'consigneeUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================
            case "getconsignee_started":
              return {...state, 'loading':true}
    case "registerconsignee_started":
              return {...state, 'loading':true}

              // case "consigneeEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "consigneeUpdate_started":
              //     return {...state, 'loading':true}
    // ====================ends here===================          

       case "getconsigneesSettings_success":

         return { ...state, 'consigneesList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerconsignee_success":
    return { ...state, 'registerconsigneeSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "consigneeEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "consigneeUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "consigneeDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerconsignee_failure":
    return { ...state, 'consigneeRegisterErrors': action.payload,  'loading':false };

case "consigneeUpdate_failure":
  return { ...state, 'consigneeUpdateErrors': action.payload,  'loading':false,  };

case 'consigneeUsersSelector_success':

  return { ...state, 'consigneeCustomersSelector': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   