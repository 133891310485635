import React,{useEffect,useState} from 'react'
import "../../../css/tabs.css";
import { NavLink,Link, Outlet,useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useTranslation } from 'react-i18next'

import {
  countLoadingLists,
  showAddConsigneeModal,
  hideAddConsigneeModal,
  registerConsignee,
} from "../../../redux/actions";

import {can} from '../../utils/roleUtils';

function LoadingListsTabs(props) {

  const [consigneeData, setConsigneeData] = useState({consignee_name:'',address:'',country:'',phone:'',email:''});

    const [backgroundTabs, setBackgroundTabs] = useState("nab-tabs");
    const [btnRightFloat, setBtnRightFloat] = useState("floating-btn-right");
    const [activeIndexPage, setActiveIndexPage] = useState(true);


const navigate = new useNavigate();



  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


const listenScrollEvent = () => {
    if (window.scrollY > 99) {
      return setBackgroundTabs("nav-tabs-fix");
    } else if (window.scrollY < 80) {
      return setBackgroundTabs("nab-tabs");
    }
  };

const listenScrollEventBtn = () => {
  if (window.scrollY > 99) {
    return setBtnRightFloat("floating-btn-move");
  } else if (window.scrollY < 80) {
    return setBtnRightFloat("floating-btn-right");
  }
};


  useEffect(()=>{
    // navigate('loadingList')
 props.countLoadingLists();
  window.removeEventListener("scroll", listenScrollEvent);
  window.removeEventListener("scroll", listenScrollEventBtn);

  window.addEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEventBtn);

  return () =>{ 
    window.removeEventListener("scroll", listenScrollEvent);
    window.removeEventListener("scroll", listenScrollEventBtn);

}
  },[])


  const handleRegConsgineeClose = () => {
    props.hideAddConsigneeModal();
  };
  

  const onInputChange=(e)=>{

    setConsigneeData({ ...consigneeData, [e.target.name]:e.target.value });
  
  }

  const handleSubmit=(e)=>{
e.preventDefault()
props.registerConsignee(consigneeData)
  }

  const {t}=useTranslation();






  return (
    <div>

<div className="container py-4  main-container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
          {/* <div className="row mb-5">
            <div className="col-lg-8 text-white py-4 text-center mx-auto">
              <h1 className="display-4">Bootstrap 4 tabs</h1>
            </div>
          </div> */}

          <div className="p-5 mb-5">
          <div className={backgroundTabs}>
              <ul
                id="myTab2"
                className="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center"
              >
                {/* <li className="nav-item flex-sm-fill" onClick={()=>setActiveIndexPage(true)}>
                  <NavLink
                   to=""
                    aria-selected="true"
                    className={
                      activeIndexPage
                      ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"                        
                    }
                  >
                  Create Loading List
                  </NavLink>
                </li>
                 */}

                
                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="pendingMode/pending"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
             {t('key_pending_lists')}
                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='pending').map((l,i)=>{
return (
  <span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>

                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="readyMode/ready"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
             {t('key_lists_ready_for_loading')}
                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='ready').map((l,i)=>{
return (
<span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>
              
                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="rejectMode/rejected"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                             {t('key_rejected_lists')}

                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='rejected').map((l,i)=>{
return (
<span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>

                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="loadedMode/loaded"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                             {t('key_loaded_lists')}


                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='loaded').map((l,i)=>{
return (
<span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>


{/* 
                <li className="nav-item flex-sm-fill">
                  <NavLink
                    to="roles"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                Receivers
                  </NavLink>
                </li> */}

              </ul>
            </div>
            <div className="tab-content">
         
            { can(currentUserPermissions,'Add Consignee') ?<button
                className={btnRightFloat}
                title="Register new consignee to system"
             onClick={()=>{props.showAddConsigneeModal()}}
              >
                {t('key_consignee')}
              </button>:''
            }


              <Outlet />
            </div>
          </div>
        </div>






 {/* ========================================================
Modal to confirm loading list
======================================================== */}

<Modal show={props.openRegConsgineeModal} onHide={handleRegConsgineeClose} size="l">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            {t('key_add_consignee')}{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleRegConsgineeClose}></CloseButton>
        </Modal.Header>
        <form onSubmit={handleSubmit}>

        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >


<div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Consginee Name">
                  <Form.Control
                    type="text"
                    name="consignee_name"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.addConsigneeErrors?.error.consignee_name?<div className="validation-error">
                    Please, input a valid consignee name, the name has already been taken !
                  </div>:''}
              </div>

            </div>



            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Address">
                  <Form.Control
                    type="text"
                    name="address"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>


            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Country">
                  <Form.Control
                    type="text"
                    name="country"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Phone">
                  <Form.Control
                    type="text"
                    name="phone"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Email">
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>


          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          {/* <button className="btn-cancel" onClick={handleRegConsgineeClose}>
            Close
          </button> */}
          <button className="btn-submit" type="submit">
              {t('key_add')}
            </button>
          {/* <button className="btn-submit" >Confirm</button> */}
        </Modal.Footer>

        </form>
      </Modal>




    </div>
  )
}


const mapStateToProps = (state) => {
  const {preDataLoadResponse } = state.preDataHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  const {
    autosDataByWarehouse,
    loading,
    openConfirmListModal,
    selectedAutosConfirm,
    countLoadLists,
    openRegConsgineeModal,
    consigneeRegistered,
    addConsigneeErrors,
    
  } = state.LoadingListHandler;

  return {
    loading: loading,
    SelectorsFetch: SelectorsFetch,
    autosDataByWarehouse: autosDataByWarehouse,
    openConfirmListModal: openConfirmListModal,
    selectedAutosConfirm: selectedAutosConfirm,
    countLoadLists:countLoadLists,
    openRegConsgineeModal:openRegConsgineeModal,
    consigneeRegistered:consigneeRegistered,
    addConsigneeErrors:addConsigneeErrors,
    preDataLoadResponse:preDataLoadResponse,

  };
};



const mapDispatchToProps = (dispatch) => {
  return {

    showAddConsigneeModal:()=>dispatch(showAddConsigneeModal()),
    hideAddConsigneeModal:()=>dispatch(hideAddConsigneeModal()),
    countLoadingLists:()=>dispatch(countLoadingLists()),
    registerConsignee:(consigneeDetails)=>dispatch(registerConsignee(consigneeDetails)),
  
  };
};




export default connect(mapStateToProps,mapDispatchToProps) (LoadingListsTabs)