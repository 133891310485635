import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import moment from 'moment';
import { AsyncPaginate } from "react-select-async-paginate";
import Checkbox from '../../elements/Checkbox';
import NodataFound from '../../pages/error/noDataFoundMain';


import {

    getSelectors,
    vehicleTrackerMultipleRegistration,
    VehicleTrackersList,
    showAndHideVehicleTrackerModal,
    VehicleTrackerRegistration,
    selectedVehicleTrackersDelete,
    usersSelectorList,
    searchVehicleTrackerList,
    inputHandler,
  
  } from "../../../redux/actions";
  
  import { useTranslation } from 'react-i18next'

function VehicleTracker(props) {


// const [addAuctionData,setAddAuctionData] = useState({name:''});
const [AuctionData,setAuctionData] = useState({name:'',onId:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);
const [isCheck, setIsCheck] = useState([]);

const [VehicleTrackerDetails, setVehicleTrackerDetails] = useState({onId:'',subject:'',body:''});
const [attachment,setAttachment]= new useState({fileName:'',file:null})
const [bodyTextCon,setBodyTextCon]=new useState(false)
const [broadcastVehicleTracker, setBroadCastVehicleTracker]=useState(false)
const [searched, setSearched] = useState(false);
const [value, onChange] = useState([]);


const {t}= useTranslation();

useEffect(()=>{
  props.VehicleTrackersList(props.currentPage);
  if(props.VehicleTrackersRegisteredSucess){
    onChange([])
  }
  if(props.VehicleTrackersDeletedSuccess){
    setIsCheck([])
  }
  },[props.VehicleTrackersMultipleRegSuccess,props.VehicleTrackersDeletedSuccess])
  
  // useEffect(()=>{
  //   if(props.notificationsEditInfoDetails){
  //     let subject = props.notificationsEditInfoDetails?.NotificationInfo?.subject;
  //     let body = props.notificationsEditInfoDetails?.NotificationInfo?.body;
      
  //     setNotificationDetails({...notificationDetails,'subject':subject,'body':body})
  //     setAttachment({'fileName':'','file':null})
  
  //   }
  
  // },[props.notificationsEditInfoDetails])
  







const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;




  const defaultAdditional = {
    page: 1
  };




  const loadOptions = async (search, page) => {
    // await sleep(1000);
    const optionsPerPage = 10;
  
      const responseJSON = await props.usersSelectorListResponse.data;
    // console.log(responseJSON.data)
    // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)
  
      let options; 
      if(responseJSON.data){
          options = responseJSON.data.map(function (data) {
          return { value: data.id, label: data.username}
        })
      }
      // inshallah will iterate it from back end
  // console.log(options)
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
  
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
  
    const hasMore = responseJSON.last_page > page;
  
    const slicedOptions = filteredOptions.slice(
      (page - 1) * optionsPerPage,
      page * optionsPerPage
    );
    // console.log(page)
    return {
      options: slicedOptions,
      hasMore
    };
  
  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    props.usersSelectorList(q,page)
  
    const { options, hasMore } = await loadOptions(q, page);
  
    return {
      options,
      hasMore,
  
      additional: {
        page:  page + 1,
      }
    };
  };
  

  const submitTrackerStatus=()=>{
    props.vehicleTrackerMultipleRegistration('public',value,props.currentPage)
  }

  const unpublicVehicles=()=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to unpublic vehicles of the selected customers",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unpublic them",
    }).then((result) => {
      if (result.isConfirmed) {
        props.selectedVehicleTrackersDelete(isCheck);
      }
    });
  }

  const handleClick = e => {
    const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
      }
   
  };
  
  const onSearch=(e)=>{
    e.preventDefault();
    
      if(props.searchTrackerText){
    setSearched(true);
      props.searchVehicleTrackerList(props.searchTrackerText,props.currentPage)
      }else{
        setSearched(false);
       
    
      }
    
    }

    const onSearchInputChange=(e)=>{
      if(e.target.value=='' && searched ){
        props.VehicleTrackersList(props.currentPage);
        setSearched(false);
    
      }
      else{
        // console.log(e.target.value)
    
        props.inputHandler({ key: e.target.name, value: e.target.value });
       
    }
      
    }
    



  return (
    <>
      


<div className="search-settings-tracker">
       <form onSubmit={onSearch}>
        <div className="row">
        <div className="col-md-2"></div>

          <div className="col-md-8  searchOuter">
      
      <div className="input-group" >
  <div className="form-outline searchOutline">
   <span><input type="search" name="searchTrackerText" className="form-control search" onChange={(e)=>onSearchInputChange(e)} placeholder="Seach by username" autoComplete="off" required/></span> 

  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>

          </div>
          
        </div>
        </form>

        </div>


      <div className="container-Layer">





      
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>


<div className="selector-container">
<div className="row">
<form>
<div className="col-md-6 col-lg-6 col-sm-6">

<label htmlFor="auction" className="picker-inputs-lebel" style={{color:'white'}}>
Please select the customers to whom you want to public their vehicles
 </label>
        <AsyncPaginate
        debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={onChange}
        isClearable
        // isDisabled={broadcastVehicleTracker?true:false}
        isMulti

        />

</div>




<div className="col-md-2">
<div className="button-container" style={{marginTop:'20px'}}>
{value?.length!=0 ?<a onClick={submitTrackerStatus} className="btn btn-primary">Change Status</a>:''}

</div>
</div>
</form>



</div>
</div>
   


  <div className="container-second">


  { 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchTrackerText}</span></p></div>
}
{props.VehicleTrackersListSuccess?.data?.data.length===0 && <NodataFound notFoundText={"No data found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}




{props.VehicleTrackersListSuccess?.data?.data.map((list,index)=>{
  return(
    <div className="row" key={index}>
    <div className='pb-3'></div>
<div className="col-md-8 col-sm-4 custom-pos">
  <Link to="">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container">
{/* <i className="fas fa-car"></i> */}
</div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>{list.username}</div>
<div className='text-uppercase date-text-t'> Status Changed at: {moment(list.created_at).format('dddd DD-MM-YYYY')}</div>

<div className="body-info">
 {list.vehicle_status_flag? 'This customer vehicles are '+list.vehicle_status_flag:''} <i className="fas fa-check-circle" style={{color:'yellow'}}></i>
</div>
</div>

{/* <div className="track-container">
<i className="fas fa-eye-slash trackList-icon"></i>

</div> */}




  </div>

  </div>

  

</Link>

<div className="checkbox-container-tracker">

<Checkbox
          key={list.id}
          type="checkbox"
          name={list.id}
          id={list.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.id)}
            />
</div>
  </div>

</div>

  )
})}


<div className="buttonContainer-status-multiple">
{isCheck?.length!=0 ?<a onClick={unpublicVehicles} className="btn btn-primary"><i className="fas fa-eye-slash trackList-icon"></i>Change Status</a>:''}

</div>


     
        </div>




</div>


{ props.VehicleTrackersListSuccess?.data?.data?.length===0 ? '':
<div>
      {props.VehicleTrackersListSuccess?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.VehicleTrackersListSuccess?.data.current_page}
              itemsCountPerPage={props.VehicleTrackersListSuccess?.data.per_page}
              totalItemsCount={props.VehicleTrackersListSuccess?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.VehicleTrackersList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}





    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,VehicleTrackersMultipleRegSuccess,searchTrackerText,showVehicleTrackerModal,VehicleTrackersListSuccess,currentPage,VehicleTrackersRegisteredSucess,VehicleTrackersDeletedSuccess } = state.VehicleTrackerHandler
  const {usersSelectorListResponse} = state.AutosHandler;
  const { preLoading,preDataLoadResponse } = state.preDataHandler;

  
  return {
    
    usersSelectorListResponse:usersSelectorListResponse,
    loading:loading,
    showVehicleTrackerModal:showVehicleTrackerModal,
    VehicleTrackersListSuccess:VehicleTrackersListSuccess,
    currentPage:currentPage,
    VehicleTrackersRegisteredSucess:VehicleTrackersRegisteredSucess,
    searchTrackerText:searchTrackerText,
    VehicleTrackersDeletedSuccess:VehicleTrackersDeletedSuccess,
    preDataLoadResponse:preDataLoadResponse,
    VehicleTrackersMultipleRegSuccess:VehicleTrackersMultipleRegSuccess,
    preLoading:preLoading,
  };
};


const mapDispatchToProps = (dispatch) => {
    return {
  
      getSelectors: () => dispatch(getSelectors()),
      VehicleTrackersList:(pageNumeber)=>dispatch(VehicleTrackersList(pageNumeber)),
      showAndHideVehicleTrackerModal:()=>dispatch(showAndHideVehicleTrackerModal()),
      VehicleTrackerRegistration:(status)=>dispatch(VehicleTrackerRegistration(status)),
      vehicleTrackerMultipleRegistration:(status,customers,currentPage)=>dispatch(vehicleTrackerMultipleRegistration(status,customers,currentPage)),
      searchVehicleTrackerList:(querry,currentPage)=>dispatch(searchVehicleTrackerList(querry,currentPage)),
      selectedVehicleTrackersDelete:(ids)=>dispatch(selectedVehicleTrackersDelete(ids)),
      usersSelectorList:(query,page)=>dispatch(usersSelectorList(query,page)),
      inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
    };
  };
  

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTracker);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================