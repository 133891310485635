import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {warehouseUpdate,hideWarehouseModal,showWarehouseModal,showEditWarehouseModal,hideEditWarehouseModal,getWarehouses,warehouseEdit,inputHandler,registerWarehouse, warehouseDelete } from "../../../redux/actions";

function Warehouses(props) {


// const [addWarehouseData,setAddWarehouseData] = useState({name:''});
const [warehouseData,setWarehouseData] = useState({name:'',onId:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);


  useEffect(() => {
    props.getWarehouses(props.currentPage);
  }, [props.registerWarehouseSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setWarehouseData({...warehouseData,'name':props.editDetails?.data?.warehouse_name||''})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value?.replace(/\s/g, '') });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setWarehouseData({...warehouseData, name:e.target.value.replace(/\s/g, '')  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hideWarehouseModal()

  };
  const handleShow = () => {

    props.showWarehouseModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditWarehouseModal();

  };

  const handleEditShow=()=>{
    props.showEditWarehouseModal()

  }




const deleteWarehouse = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this warehouse",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.warehouseDelete(id, props.currentPage);
    }
  });
};





const submitWarehouse = (e) => {
  e.preventDefault();
  let name=props?.name;
  if(name){
    props.registerWarehouse(name)
  }
}


const UpdateWarehouse = (e) => {
  e.preventDefault();
  let name=warehouseData.name;
  let id=warehouseData.onId;
  let currentPage=props.warehousesList?.data?.current_page;
  props.warehouseUpdate(id,name,currentPage);
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7">   
      <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add Warehouse</a></div>
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.warehousesList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-3 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.warehouse_name}</p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a  onClick={
                    ()=>{
                      setWarehouseData({...warehouseData,'onId':list.id})
                      props.warehouseEdit(list.id)
                      setTimeout(()=>{
                        handleEditShow()

                      },190)

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a onClick={()=>deleteWarehouse(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.warehousesList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.warehousesList?.data?.current_page}
              itemsCountPerPage={props.warehousesList?.data?.per_page}
              totalItemsCount={props.warehousesList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getWarehouses(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New Warehouse </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitWarehouse}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
              <div className="validation-error"><i className="fas fa-info"></i> Note: Warehouse name don't support space key</div>

                <FloatingLabel label="Warehouse Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.warehouseRegisterErrors?.error?.warehouse_name?true:false}
                  />

                </FloatingLabel>

                {props.warehouseRegisterErrors?.error?.warehouse_name ? (
                  <div className="validation-error">
                    The warehouse name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Warehouse </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={UpdateWarehouse}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="validation-error"><i className="fas fa-info"></i> Note: Warehouse name don't support space key</div>
                <FloatingLabel label="Warehouse Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="editWarehouseName"
                    value={warehouseData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.warehouseUpdateErrors?.error?.warehouse_name ?true:false}
                  />

                </FloatingLabel>

                {props.warehouseUpdateErrors?.error?.warehouse_name ? (
                  <div className="validation-error">
                    The warehouse name already exists, you can't update it with this warehouse name !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,warehousesList,showWarModal,showEditWarModal,name,registerWarehouseSuccess,warehouseRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,warehouseUpdateErrors} = state.SettingsWarehouseHandler;

  return {
    loading: loading,
    name:name,
    warehouseRegisterErrors:warehouseRegisterErrors,
    currentPage:currentPage,
    warehousesList:warehousesList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerWarehouseSuccess:registerWarehouseSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    warehouseUpdateErrors:warehouseUpdateErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


     inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
      getWarehouses:(currentPage)=>dispatch(getWarehouses(currentPage)),
      showWarehouseModal:()=>dispatch(showWarehouseModal()),
      hideWarehouseModal:()=>dispatch(hideWarehouseModal()),
      showEditWarehouseModal:()=>dispatch(showEditWarehouseModal()),
      hideEditWarehouseModal:()=>dispatch(hideEditWarehouseModal()),
      registerWarehouse:(name)=>dispatch(registerWarehouse(name)),
      warehouseEdit:(id)=>dispatch(warehouseEdit(id)),
      warehouseUpdate:(id,name,currentPage)=>dispatch(warehouseUpdate(id,name,currentPage)),
      warehouseDelete:(id,currentPage)=>dispatch(warehouseDelete(id,currentPage)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Warehouses);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================