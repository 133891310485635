import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import "../../../css/pagination.css";
import Swal from "sweetalert2";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ErrorNoData from '../error/NoDataError';
import NodataFound from '../error/noDataFoundMain';
import Loader from '../../elements/Loader';
import {
  usersList,
  userDelete,
  userEdit,
  hideUpdateModal,
  userUpdate,
  inputHandler,
  searchUsersList,
  rolePicker
} from "../../../redux/actions";
import _ from "lodash";
import {can} from '../../utils/roleUtils';
function UsersList(props) {
  const [userRegErrors, setUserRegErrors] = useState({
    password: null,
    role: false,
  });

  const [userUpdateData, setUserUpdateData] = useState({
    name: "",
    username: "",
    email: "",
    contact:"",
    role: [],
    password: "",
    confirm_password: "",
  });

  // let databaseSelectedValue;
  // if (!_.isUndefined(props.userEditResponse)) {
  //   databaseSelectedValue = {
  //     value: props.userEditResponse.role_id,
  //     label: props.userEditResponse.role,
  //   };
  // }

  let databaseSelectedValue; 
if(props?.userEditResponse?.role_ids_and_names){
  databaseSelectedValue = Object.entries(props.userEditResponse.role_ids_and_names).map(([roleId, roleName]) => ({
    value: roleId,
    label: roleName
  }));
  
}





  const [searched, setSearched] = useState(false);

  // useEffect(() =>{
  //   props.rolePicker();

  // },[props.currentPage, 
  //   props.userRegistrationResponse,
  //   props.userDeletionResponse,
  //   props.userUpdateResponse])

  useEffect(() => {
    props.fetchUsersList(props.currentPage);
    // props.rolePicker();
  }, [
    props.userRegistrationResponse,
    props.userDeletionResponse,
    props.userUpdateResponse,
  ]);


  
  useEffect(() => {

    setUserUpdateData({
      contact: !_.isUndefined(props.userEditResponse)
      ? props.userEditResponse.contact
      : "",   
      
      name: !_.isUndefined(props.userEditResponse)
        ? props.userEditResponse.account_name
        : "",

      username: !_.isUndefined(props.userEditResponse)
        ? props.userEditResponse.username
        : "",

      email: !_.isUndefined(props.userEditResponse)
        ? props.userEditResponse.email
        : "",

      role: !_.isUndefined(props.userEditResponse) ? databaseSelectedValue : "",
      password: "",
      confirm_password: "",
    });
  }, [props.userEditResponse]);


  
  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;

  const onDeleteUser = (users_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.userDelete(users_id, props.currentPage);
      }
    });
  };

  const handleClose = () => {
    setUserUpdateData({...userUpdateData,contact:''})
    props.hideUpdateModal()
  };
  // const handleShow = () => props.showRegisterModal();

  const updateUser = (e) => {
    e.preventDefault();
    let name = userUpdateData.name;
    let username = userUpdateData.username;
    let email = userUpdateData.email;
    let contact = userUpdateData.contact;

    let role= userUpdateData.role;

    //for password validation
    let password = userUpdateData.password;
    let confirm_password = userUpdateData.confirm_password;
    if (userUpdateData.role === null) {
      setUserRegErrors({ role: "the field is required" });
    }

    let current_page = props.usersListResponse.data.current_page;
    let id = props.userEditResponse.users_id;

    if( userUpdateData.role.length != 0){

    if (password === confirm_password) {
      setUserRegErrors({password:null})
      setUserRegErrors({role:false})

      props.userUpdate(
        id,
        current_page,
        name,
        username,
        email,
        contact,
        role,
        password,
        confirm_password
      );
    } else {
      setUserRegErrors({ password: "Please match the passwords" });
    }

  }else{
  setUserRegErrors({role:true})

}
  };

  const showEditDetails = (id) => {
    props.userEdit(id);
  };

  const handleSearchPages=(pageNumber)=>{
    props.searchUsersList(props.searchUsers,pageNumber)
  
  }



  // ============================================
  // Validation purposes (granular level)
  // ============================================

  const handleChangeOption = (selected) => {
    setUserUpdateData({ ...userUpdateData, role: selected });
  };

  const onInputchange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setUserUpdateData({ ...userUpdateData, [e.target.name]: e.target.value });
  };

const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    
    props.fetchUsersList(props.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}

const onSearch=(e)=>{
e.preventDefault();

  if(props.searchUsers){
setSearched(true);
  props.searchUsersList(props.searchUsers,props.currentPage)
  }else{
    setSearched(false);
   

  }

}
  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 55,
      baseUnit: 4,
    },
  });

  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  // ======================================= for picker
  let roleOptions; 
if(props?.rolePickerData.roles){
  roleOptions = props?.rolePickerData?.roles.map(function (role) {
    return { value: role.id, label: role.name }
  })
}

  // ======================================= for picker

  const viewUsersDetails = (name, username, role, email,contact) => {
    Swal.fire({
      title:
      `<strong style="font-size:16px; font-weight:550; color:#154b8a"> ${name.toUpperCase()}</strong> <strong style="font-size:16px; font-weight:550;"> DETAILS</strong>`,
    
      html:
      `<div style="text-align:left"> <span style="color:#154b8a;">Name: </span> &nbsp; ${name} `+
      `<div style="text-align:left"> <span style="color:#154b8a;">Username: </span> &nbsp; ${username} `+
      `<div style="text-align:left"> <span style="color:#154b8a;">Role: </span> &nbsp; ${role} `+
      `<div style="text-align:left"> <span style="color:#154b8a;">${email?'Email:':''}</span> &nbsp; ${email?email:''} `+
      `<div style="text-align:left"> <span style="color:#154b8a;">${contact?'Contact No:':''} </span> &nbsp; ${contact?contact:''} `+''
        ,

      showCloseButton: true,
        //  showCancelButton: true,
        //  cancelButtonText:'Close',

      showConfirmButton: false,
    });
  };

  let usernameError;
  let roleError;
  let passwordError;
  let emailError;

  if (props.userUpdateErrors) {
    usernameError = props.userUpdateErrors.error.username;
    roleError = props.userUpdateErrors.error.role;
    passwordError = props.userUpdateErrors.error.password;
    emailError = props.userUpdateErrors.error.email;
  }

  return (
    <>
    {props?.loading ? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40vh"}
            />:
      <div className="row text-center">
{ 
  searched && <div><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchUsers}</span></p></div>
}
{
          props.usersListResponse?.data?.data?.length===0 && <NodataFound notFoundText={"No User Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}
{/* {props.usersListResponse?.data.data==='no data' && <ErrorNoData imgHeight={'270px'} imgWidth={'270px'} headerTextColor={'white'} lowerTextColor={'white'}/>} */}
        {props.usersListResponse ?
          props.usersListResponse.data.data?.map((list, index) => {
            return (
              <div
                className="col-xl-3 col-sm-4 col-md-3 mb-5 card-outer"
                key={index}
              >
                <div className="bg-white rounded shadow-sm py-5 px-4 list-card">
                  <img
                    src={require("../../assets/male.jpg")}
                    alt=""
                    width="100"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h6 className="mb-0">{list.username}</h6>
                  {list?.roles.map((item,ind)=>{
                    return(
                      <div key={ind} className="small text-uppercase text-muted" style={{fontSize:'11px',overflowY:'scroll'}}>
                         {/* <div class="border-top my-1"></div>  */}
 {item}
                    </div>
                    )
                  })
                  }
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                    {list.account_name==="Sabaoon"?"":

                      <a
                        onClick={() => {
                          viewUsersDetails(
                            list.account_name,
                            list.username,
                            list.roles,
                            list.email,
                            list.contact
                          );
                        }}
                        className="icons-link"
                      >
                        <i className="fa fa-eye eye"></i>
                      </a>}
                    </li>
                    <li className="list-inline-item">
                    {list.account_name==="Sabaoon"?"":

                      <a
                        onClick={() => {
                          setTimeout(()=>{
                            showEditDetails(list.users_id);

                          },550)
                        }}
                        className="icons-link"
                      >
                        <i className="fa fa-edit edit"></i>
                      </a>
                      }
                    </li>

                    
                 {can(currentUserPermissions,'Delete User') ?  <li className="list-inline-item">
                 {list.account_name==="Sabaoon"?"":
                      <a
                        onClick={() => onDeleteUser(list.users_id)}
                        className="icons-link"
                      >
                        <i className="fa fa-trash trash"></i>
                      </a>
                 } 
                      
                    </li>:""}
                  </ul>
                </div>
              </div>
            );
          }):''}
      </div>
    }
      {props.usersListResponse?.data?.data.length >0 ? (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.usersListResponse.data.current_page}
              itemsCountPerPage={props.usersListResponse.data.per_page}
              totalItemsCount={props.usersListResponse.data.total}
              //   pageRangeDisplayed={5}
              onChange={searched==true? handleSearchPages :(pageNumber) => props.fetchUsersList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):props.usersListResponse?.data==='no data' &&(
        ''  
      )}

      <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchUsers" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>



      {/* ====================================================
      update user 
      ==================================================== */}

      <Modal show={props.showModal} onHide={handleClose} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Update User </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={updateUser}>
          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={userUpdateData.name}
                    required
                    onChange={(e) => onInputchange(e)}
                  />
                </FloatingLabel>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Username">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="username"
                    value={userUpdateData.username}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={usernameError ? true : false}
                  />
                </FloatingLabel>
                {usernameError ? (
                  <div className="validation-error">
                    The username already exists in our system !
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Email Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={userUpdateData.email || ""}
                    onChange={(e) => onInputchange(e)}
                    isInvalid={emailError ? true : false}
                  />
                </FloatingLabel>
                {emailError ? (
                  <div className="validation-error">
                    Please, input a valid email address, e.g ghulam@gmail.com !
                  </div>
                ) : (
                  ""
                )}
              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel
              
                  label="Contact No"
                >
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={userUpdateData.contact||''}
                    onChange={(e)=>onInputchange(e)}
                  />

                </FloatingLabel>

                
              </div>



            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Select
                  options={roleOptions}
                  theme={theme}
                  placeholder="Select Role"
                  name="role"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={userUpdateData.role}
                  defaultValue={databaseSelectedValue}
                  onChange={(e) => handleChangeOption(e)}
                  isClearable={true}
                  isMulti
                />

         {userRegErrors.role?<div className="validation-error">The role field is required !</div>:''}
         {roleError?<div className="validation-error">The role field is required !</div>:''}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Password">
                  <Form.Control
                    type="password"
                    placeholder=" "
                    name="password"
                    value={userUpdateData.password}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={
                      passwordError || userRegErrors.password ? true : false
                    }
                  />
                </FloatingLabel>
                {userRegErrors.password || passwordError ? (
                  <div className="validation-error">Doesn't match !</div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Confirm Password">
                  <Form.Control
                    type="password"
                    placeholder=" "
                    name="confirm_password"
                    value={userUpdateData.confirm_password}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={
                      passwordError || userRegErrors.password ? true : false
                    }
                  />
                </FloatingLabel>
                {userRegErrors.password || passwordError ? (
                  <div className="validation-error">
                    Doesn't match with password!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    rolePicker,
    loading,
    usersListResponse,
    userDeletionResponse,
    currentPage,
    userEditResponse,
    showModal,
    userUpdateErrors,
    userUpdateResponse,
    name,
    username,
    email,
    password,
    confirm_password,
    searchUsers,
  } = state.userManagementHandler;
  const { userRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
    usersListResponse: usersListResponse,
    userRegistrationResponse: userRegistrationResponse,
    userDeletionResponse: userDeletionResponse,
    userUpdateResponse: userUpdateResponse,
    currentPage: currentPage,
    userEditResponse: userEditResponse.data,
    userUpdateErrors: userUpdateErrors,
    showModal: showModal,
    preDataLoadResponse: preDataLoadResponse,
    name: name,
    username: username,
    email: email,
    password: password,
    confirm_password: confirm_password,
    searchUsers: searchUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersList: (pageNumber) => dispatch(usersList(pageNumber)),

    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),

    userEdit: (id) => dispatch(userEdit(id)),

    hideUpdateModal: () => dispatch(hideUpdateModal()),

    userUpdate: (
      id,
      currentPage,
      name,
      username,
      email,
      contact,
      role,
      password,
      confirmPassword
    ) =>
      dispatch(
        userUpdate(
          id,
          currentPage,
          name,
          username,
          email,
          contact,
          role,
          password,
          confirmPassword
        )
      ),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchUsersList:(querry,currentPage)=>dispatch(searchUsersList(querry,currentPage)),
    rolePicker:()=>dispatch(rolePicker())

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
