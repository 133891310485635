const InitialStates = {
  usersListResponse:null,
    loading: false,
    userDeletionResponse:[],
    userEditResponse:[],
    userUpdateErrors:null,
    currentPage:1,
    showModal:false,
    account_name:'',
    name:'',
    username:'',
    email:'',
    password:'',
    confirm_password:'',
    searchUsers:'',
    rolePicker:[],
    

  };
  
  export default (state = InitialStates, action) => {
    switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

case "hideModalAction":
  return { ...state, 'showModal': false, 'contact':''  };

  case "searchUsers_started":
     
    return { ...state, 'loading': true,  };

      case "fetch_started":
     
        return { ...state, 'loading': true,  };
  
        case "fetch_error":
          return { ...state, 'loading': false,};

          case "rolePicker_success":
            return { ...state,  'rolePicker':action.payload , 'loading':false,  };
      
        case "fetch_success":
     
        return { ...state, 'usersListResponse': action.payload , 'loading':false, 'currentPage':action.payload.data.current_page };
  
  case "userDeletion_success":
   
        return { ...state, 'userDeletionResponse': action.payload, 'loading':false, 'currentPage':action.payload.currentPage };

  case "userEditDetails_success":
  
  return { ...state, 'userEditResponse': action.payload, 'loading':false, 'showModal':true };

  case "userUpdate_success":

    return { ...state, 'userUpdateResponse': action.payload, 'loading':false, 'showModal':false,'currentPage':action.payload.currentPage,'userUpdateErrors':null };

    case "userUpdate_failure":

      return { ...state, 'userUpdateErrors': action.payload, 'loading':false, };


//for searching users list 
      case "searchUsers_success":
       
        return { ...state, 'usersListResponse': action.payload, 'loading':false };
  
      default:
        return state;
    }
  };
  