import React, { useState, useEffect } from "react";

function Otherpage() {

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('tokenUser'));
    // console.log(items);
    if (items) {
     setItems(items);
    //  console.log(items[1]);
    }
  }, []);

  return (
    <div>Otherpage</div>
  )
}

export default Otherpage