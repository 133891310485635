import "../../../css/list.css";
import "../../../css/searchBox.css";
import "../../../css/fixbar.css";
import "../../../css/rightBar.css";
import {can,hasRole,hasAnyRole} from "../../utils/roleUtils";
import "../../../css/circleAroundNumber.css"
import "react-datepicker/dist/react-datepicker.css";
import "../../../css/badges.css"
import NodataFound from '../../pages/error/noDataFoundMain';
import Logo from "../../assets/normLogo.png";
import BillView from '../../pages/autos/BillView';
import { AsyncPaginate } from "react-select-async-paginate";

import React, { useState, useEffect,useRef } from "react";
import { useNavigate,Link,NavLink,useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ProgressBar from "react-bootstrap/ProgressBar";
import Checkbox from '../../elements/Checkbox';
import { useTranslation } from 'react-i18next'

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Swal from "sweetalert2";
import Loader from "../../elements/Loader";
import CloseButton from "react-bootstrap/esm/CloseButton";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import _ from "lodash";
import RightSidebar from '../../elements/RightSidebar';
import axios from "axios";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import ImagesCarousel from '../autos/AutosCarousel';
import ErrorNo from '../error/NoDataError';
import AutosCommunication from '../autos/AutosCommunication'
import MessageBox from "../../elements/MessageBox";

import {getAutoAdditionalDetails,
  getSelectors,
  storeAutoData,
  showAutoRegModal,
  hideAutoRegModal,
  autosList,
  hideAutoEditModal,
  showAutoEditModal,
  showAutoAdditionalModal,
  hideAutoAdditionalModal,
  autoAdditionalRegistration,
  resetProgressBar,
  getImages,
  changePointOfLoading,
  filterListForWarhouses,
  inputHandler,
  searchAutosList,
  autoDelete,
  selectedAutosDelete,
  showInvoiceRegModal,
  hideInvoiceRegModal,
  showInvoiceInfoModal,
  hideInvoiceInfoModal,
  registerAutoInvoice,
  getAutoInvoices,
  hideAutoCommuncationNotesModal,
  showAutoCommuncationNotesModal,
  usersSelectorList,
  storeImagesAutos,
  getAutoCommunication,
  getLatestNotificationDetails,
  NotificationRead,

} from "../../../redux/actions";
// import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import ImagesGrid from '../autos/AutoImagesGrid';
import CryptoAES from 'crypto-js/aes';
import Cookies from "js-cookie";

function AutosList(props) {
  // const [show, setShow] = useState('');
  const [autosId, setAutosID] = useState('');
  const [value, onChange] = useState(null);
  const [editSelector, setEditSelector] = useState(null);

  const [closeMessageBox, setCloseMessageBox] = useState(false);

  const [Loading, setLoading] = useState(false);
  const [headerSearch, setHeaderSearch] = useState('autoListSearchHeaderSticky');
  const [searched, setSearched] = useState(false);

  const [dragAndDropText, setdragAndDropText] = useState(
    "Upload or Drag and Drop your Invoice here"
  );
  //for additional images upload here

  const [dragAndDropTextImages, setdragAndDropTextImages] = useState(
    "Upload or Drag and Drop images here"
  );
  const [dragAndDropSelected, setdragAndDropSelected] = useState(false);

  //for additional invoice upload
  const [dragAndDropAdditionalInvoiceText, setdragAndDropAdditionalInvoiceText] = useState(
    "Upload or Drag and Drop your Invoice here"
  );
  const [dragAndDropAdditionalInvoiceSelected, setdragAndDropAdditionalInvoiceSelected] = useState(false);


 

  const [dragAndDropSelectedImages, setdragAndDropSelectedImages] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);


  const [autoDetails, setAutoDetails] = useState([
    { model: "", make: "", year: "" },
  ]);

  const [autoRegistrationData, setAutoRegistrationData] = useState(
    { vin:'', lot:'', autoType:null, model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:null,auto_price:'' },
  );
  
  const [autoRegistrationAdditionalData, setAutoRegistrationAdditionalData] = useState(
    {onId:'', auto_catagory:'', images:[],containerNoBooking:'', pickUp:null, toWarehouse:null, arrival_date:null, unloading_date:null, title_status:'', title_received:null, keys:'',terminal_notes:'', vin:'', lot:'', autoType:null, model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:null, auto_price:'' },
  );
  
  const [autoAdditionalDataError, setAutoAdditionalDataErrors] = useState(
    {auto_catagory_error:''},
  );

  const [billInfo, setBillInfo] = useState(
    { autos_id:'', invoice_catagory:'', amount:'', notes:'',bill_to:'' },
  );

  const [isCheck, setIsCheck] = useState([]);


  const billCatagory = [
    { value: 'Credit', label: 'Credit' },
    { value: 'Debit', label: 'Debit' },
   
  ]



  const navigate = new useNavigate();

  useEffect(()=>{
    props.getSelectors();
    setAutoRegistrationData({...autoRegistrationData,autoType:{ value: "Auto", label: "Auto" }})

  },[]);




  const params = useParams();
  const {t}=useTranslation();

useEffect(()=>{
  
 let ware= params.warehouse?.replace(/\d+/,'');
 let placement= params.placement?.replaceAll('-',' ');
//  console.log(placement);
//  console.log(ware);
if(props.selectedAutosDeleted){
setIsCheck([]);
}

 if(props.autoRegistrationSuccess||props.autoAdditionalRegistrationSuccess||props.pointOfLoadingChangeSuccess||props.autoDeleted||props.selectedAutosDeleted || props.autosImagesUploadResponse || props.imagesDeletionSuccess){
  props.autosList(props?.currentPage);

 }
if(placement && ware){

  props.filterListOfWarhouses(ware,placement,props.currentPage);


}else{
props.autosList(props?.currentPage);
}

if(props.notificationsDeletedSuccess){
  props.getLatestNotificationDetails()
}


},[props.autoRegistrationSuccess,props.autoAdditionalRegistrationSuccess,props.pointOfLoadingChangeSuccess,props.autoDeleted,props.selectedAutosDeleted,props.autosImagesUploadResponse,props.imagesDeletionSuccess,props.notificationsDeletedSuccess])

useEffect(()=>{
  if(props.uploadPercentage){
  setTimeout(()=>{
props.resetProgressBar();
  },2000)
}
},[props.uploadPercentage])


useEffect(()=>{
  if(props?.cleanValues){

    setAutoRegistrationData(  {...autoRegistrationData, vin:'', lot:'', model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:null },
    )
    setdragAndDropSelected(false);
    setdragAndDropText("Upload or Drag and Drop your Invoice here")
    }

    if(props.cleanAdditionalValues){
      setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,images:[]})
      setdragAndDropSelectedImages(false)
      setdragAndDropTextImages("Upload or Drag and Drop your images here")
 
    }
    
},[props?.cleanValues,props.cleanAdditionalValues,props.autosImagesUploadResponse])

useEffect(()=>{
  let editData=props.getAdditionalRegDataSuccess?.data;   
  setEditSelector( 
{value:editData?.userId, label:editData?.customerName}
    )
  setAutoRegistrationAdditionalData({
    ...autoRegistrationAdditionalData,
    auto_catagory:editData?.auto_catagory?{value:editData.auto_catagory, label:editData?.auto_catagory}:null,
    title_status:editData?.title_status?{value:editData.title_status, label:editData?.title_status}:'',
    containerNoBooking:editData?.container_num_booking||'',
    pickUp:editData?.promise_pickup? moment(editData?.promise_pickup).toDate():null,
    toWarehouse:editData?.delivered_warehouse? moment(editData?.delivered_warehouse).toDate():null,
    title_received:editData?.title_received? moment(editData?.title_received).toDate():null,
    arrival_date:editData?.arrival_date? moment(editData?.arrival_date).toDate():null,
    unloading_date:editData?.unloading_date? moment(editData?.unloading_date).toDate():null,
    keys:editData?.keys||'',
 
    terminal_notes:editData?.terminal_notes||'',

    // toWarehouse:props.getAdditionalRegDataSuccess?.data?.promise_pickup,
// ==========================================
//  non admin edit details
// ==========================================

vin:editData?.vin||'',
model:editData?.model||'',
make:editData?.make||'',
year:editData?.year||'',
color:editData?.color||'',
lot:editData?.lot||'',
dismantle:editData?.to_dismantle||'',
self_delivered:editData?.self_delivered||'',


customer_notes:editData?.customer_notes||'',

purchase_date:editData?.purchase_date? moment(editData?.purchase_date).toDate():null,
payment_to_auction:editData?.payment_auction? moment(editData?.payment_auction).toDate():null,
auction:editData?.auction?{value:editData.auction, label:editData?.auction}:'',
city:editData?.city?{value:editData.city, label:editData?.city}:'',
buyer_no:editData?.buyer_no?{value:editData.buyer_no, label:editData?.buyer_no}:'',
port:editData?.port?{value:editData.port, label:editData?.port}:'',
point_of_loading:editData?.point_loading?{value:editData.point_loading, label:editData?.point_loading}:'',
autoType:editData?.auto_type?{value:editData.auto_type, label:editData?.auto_type}:'',
auto_price:editData?.auto_price||'',


  
  })

},[props.getAdditionalRegDataSuccess])




// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
const hasRole=(role)=>(props.preDataLoadResponse?.currentUserRole||[]).find((p)=>p==role)?true:false;
// const hasAnyRole = (...roles) => {
//   const userRoles = props.preDataLoadResponse?.currentUserRole || [];
//   return roles.find(role => userRoles.includes(role)) !== undefined;
// };
const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



const listenScrollEvent = () => {
  if (window.scrollY > 99) {
    return setHeaderSearch("autoListSearchHeaderFixed");
  } else if (window.scrollY < 80) {
    return setHeaderSearch("autoListSearchHeaderSticky");
  }
};

useEffect(() => {
  window.removeEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEvent);
  return () => window.removeEventListener("scroll", listenScrollEvent);
}, []);



const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    
    props.autosList(props?.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}

const onSearch=(e)=>{
e.preventDefault();

  if(props.searchAutos){
setSearched(true);
  props.searchAutosList(props.searchAutos,props.currentPage)
  }else{
    setSearched(false);
   

  }

}

 // ==========================************================
  // All selectors options
  // ==========================************================
  let autoCatagoryOptions; 
if(props?.SelectorsFetch?.auction){
  autoCatagoryOptions = props.SelectorsFetch?.autoCatagory.map(function (data) {
    return { value: data.catagory_name, label: data.catagory_name }
  })
}

let auctionOptions; 
if(props?.SelectorsFetch?.auction){
  auctionOptions = props.SelectorsFetch?.auction.map(function (data) {
    return { value: data.auction_name, label: data.auction_name }
  })
}


let cityOptions; 
if(props?.SelectorsFetch?.city){
  cityOptions = props.SelectorsFetch?.city.map(function (data) {
    return { value: data.city_name, label: data.city_name }
  })
}

let landingPointOptions; 
if(props?.SelectorsFetch?.warehouse){
  landingPointOptions = props.SelectorsFetch?.warehouse.map(function (data) {
    return { value: data.warehouse_name, label: data.warehouse_name }
  })
}

let portOptions; 
if(props?.SelectorsFetch?.port){
  portOptions = props.SelectorsFetch?.port.map(function (data) {
    return { value: data.port_name, label: data.port_name }
  })
}

let buyerOptions; 
if(props?.SelectorsFetch?.buyerNumber){
  buyerOptions = props.SelectorsFetch?.buyerNumber.map(function (data) {
    return { value: data.buyer_no, label: data.buyer_no }
  })
}


let billersOptions; 
if(props?.SelectorsFetch?.billers){
  billersOptions = props.SelectorsFetch?.billers.map(function (data) {
    return { value: data.id, label: data.biller_name }
  })
}

let autoTypeOptions; 
if(props?.SelectorsFetch?.autoType){
  autoTypeOptions = props.SelectorsFetch?.autoType.map(function (data) {
    return { value: data.type_name, label: data.type_name }
  })
}








const handleChangeSelectors = (e,selectorName) => {
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
  setAutoRegistrationData({ ...autoRegistrationData, [selectName]:e });
};

const handleMoreInfoSelectors = (e,selectorName) => {
  setAutoAdditionalDataErrors({auto_catagory_error:''})
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [selectName]:e });

};

const handleBillSelector = (e,selectorName) => {
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
setBillInfo({ ...billInfo, [selectName]:e });

};


const handleCheckbox =(e)=>{
if(e.target.checked){
  setAutoRegistrationData({ ...autoRegistrationData, [e.target.name]:"true" });

}else{
  setAutoRegistrationData({ ...autoRegistrationData, [e.target.name]:"false" });

}

}



const handleCheckboxAddtional =(e)=>{
  if(e.target.checked){
    setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [e.target.name]:"true" });
  
  }else{
    setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [e.target.name]:"false" });
  
  }
  
  }




const onInputChange=(e)=>{

  setAutoRegistrationData({ ...autoRegistrationData, [e.target.name]:e.target.value });

}





const onInputChangeBill=(e)=>{

setBillInfo({ ...billInfo, [e.target.name]:e.target.value });

}


const onInputChangeAdditional=(e)=>{

  setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [e.target.name]:e.target.value });

}

const handleDateInputChange=(e,dateSelectorName)=>{
// let formated = moment(e).format('YYYY-MM-DD')
  setAutoRegistrationData({ ...autoRegistrationData, [dateSelectorName]:e});

}


const handleDateInputChangeAdditional=(e,dateSelectorName)=>{
  // let formated = moment(e).format('YYYY-MM-DD')
    setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [dateSelectorName]:e});
  
  }


const handleChange = (file) => {
  
  if (_.isEmpty(file)) {
    setdragAndDropSelected(true);
    setdragAndDropText(file.name);
    setAutoRegistrationData({...autoRegistrationData, 'invoice':file})
  } else {
    setdragAndDropSelected(false);
  }
};





const handleChangeAdditional = (file) => {

  if (_.isEmpty(file)) {
    setdragAndDropAdditionalInvoiceSelected(true);
    setdragAndDropAdditionalInvoiceText(file.name);
    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData, 'invoice':file})
  } else {
    setdragAndDropAdditionalInvoiceSelected(false);
  }
};



const handleChangeImages = (FileList) => {
  // console.log(FileList)
  if (FileList.length>0) {
    setdragAndDropSelectedImages(true);
    if(FileList.length==1){
    setdragAndDropTextImages(`${FileList.length} image`);
    }else{
      setdragAndDropTextImages(`${FileList.length} images`);
    }
    const imagesArray = [];

    for (let i = 0; i < FileList.length; i++) {      
      imagesArray.push(FileList[i]);
    }

    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData, 'images':imagesArray})
  } else {
    setdragAndDropSelectedImages(false);

  }
};


const handlePageChange=(pageNumber) => {
  // console.log(`active page is ${pageNumber}`);
  

let warehouse = params.warehouse?.replace(/([A-Za-z]+\d+)|\s+/g, (match, p1) => {
  if (p1) {
    return p1; // If it's a prefix followed by digits, return it as is
  }
  return ''; // Otherwise, replace spaces with empty
});

  props.filterListOfWarhouses(warehouse,params.placement?.replaceAll('-',' '),pageNumber)

}

const handleSearchPages=(pageNumber)=>{
  props.searchAutosList(props.searchAutos,pageNumber)

}

const onStoreAdditionalData=(e)=>{
e.preventDefault();
if(autoRegistrationAdditionalData.auto_catagory==null){

  setAutoAdditionalDataErrors({auto_catagory_error:'The auto placement is required.'})

}else{

  props.autoAdditionalRegistration(autoRegistrationAdditionalData,props.autosListResponse?.data?.current_page,editSelector);

}

}

const submitChangePointOfLoading=(e)=>{

  e.preventDefault();
  if(autoRegistrationAdditionalData.point_of_loading==null || autoRegistrationAdditionalData.auto_catagory==null){
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Please fill both selection fields !",
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
    });
}else{
  props.changePointOfLoading(autoRegistrationAdditionalData,props.autosListResponse?.data?.current_page);

}

}



const submitAutoInvoice=(e)=>{
e.preventDefault();
if(billInfo.invoice_catagory==""){
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Please select the type of invoice !",
    timer: 2000,
    timerProgressBar: true,
    toast: true,
    confirmButtonColor: "#0a4b85",
  });
}else{
  props.registerAutoInvoice(billInfo)

}


}




  const viewAutoInfo = (id) => {
    navigate(`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListGhulam_123'))}`);
  };

  //functions for model pop up and close
  const handleEditClose = () => props.hideAutoEditModal();
  const handleAdditionalClose = () => {
    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,invoice:null})
    setdragAndDropAdditionalInvoiceSelected(false)
    setdragAndDropAdditionalInvoiceText("Upload or Drag and Drop your images here")
    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,images:[]})

    setdragAndDropSelectedImages(false)
    setdragAndDropTextImages("Upload or Drag and Drop your images here")
    props.hideAutoAdditionalModal()

    setAutoAdditionalDataErrors({auto_catagory_error:''})

  };

  const handleClose = () => props.hideAutoRegModal();
  const handleShow = () => props.showAutoRegModal();
  const handleInvoiceRegClose =() => props.hideInvoiceRegModal();
  const handleInvoiceInfoClose=()=>props.hideInvoiceInfoModal();
  const handleCommunicationModalClose=()=>props.hideAutoCommuncationNotesModal();

  // ==========================************================
  // Function for VIN Decoding
  // ==========================************================

  const vinValidation = vin => {
    if(vin){
    const regex = new RegExp('[A-HJ-NPR-Z0-9]{17}');    
    return regex.test(vin);
    }
  };



  const vinDecode = async (vin) => {

    setAutoRegistrationData({...autoRegistrationData, vin:vin});
    // console.log(vin);
    if (vinValidation(vin)) {
      setLoading(true);
      axios.get(`https://auto.dev/api/vin/${vin}`,{ params: { apikey: 'ZrQEPSkKYmFpZGFyLnNhYmFvb25AZ21haWwuY29t' } })
      .then(function (response) {
        // console.log(response);
        setAutoRegistrationData({
            ...autoRegistrationData,
            vin:vin,
            model:response.data.model?.name,
            make:response.data.make?.name,
            year:response.data?.years[0]?.year
            });

            setLoading(false);

      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There is some problem in the network!, please try manual process",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      });

      
   

    } else {
      if (vin.length >= 17) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your VIN is wrong!",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      }
    }
  };





  const vinDecodeAdditional = async (vin) => {

    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData, vin:vin});
    // console.log(vin);
    if (vinValidation(vin)) {
      setLoading(true);
      axios.get(`https://auto.dev/api/vin/${vin}`,{ params: { apikey: 'ZrQEPSkKYmFpZGFyLnNhYmFvb25AZ21haWwuY29t' } })
      .then(function (response) {
        // console.log(response);
        setAutoRegistrationAdditionalData({
            ...autoRegistrationAdditionalData,
            vin:vin,
            model:response.data.model?.name,
            make:response.data.make?.name,
            year:response.data?.years[0]?.year
            });

            setLoading(false);

      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There is some problem in the network!, please try manual process",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      });

      
   

    } else {
      if (vin.length >= 17) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your VIN is wrong!",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      }
    }
  };




  const uploadImages = () =>{

    props.storeImagesAutos(autoRegistrationAdditionalData)
  
  }
  




  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
      baseUnit: 4,
    },
  });

 

  const defaultAdditional = {
    page: 1
  };
  
  


  const loadOptions = async (search, page) => {
    // await sleep(1000);
    const optionsPerPage = 10;

      const responseJSON = await props.usersSelectorListResponse.data;
    // console.log(responseJSON.data)
    // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)

      let options; 
      if(responseJSON.data){
          options = responseJSON.data.map(function (data) {
          return { value: data.id, label: data.username}
        })
      }
      // inshallah will iterate it from back end
  // console.log(options)
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
  
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = responseJSON.last_page > page;

    const slicedOptions = filteredOptions.slice(
      (page - 1) * optionsPerPage,
      page * optionsPerPage
    );
    // console.log(page)
    return {
      options: slicedOptions,
      hasMore
    };

  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    props.usersSelectorList(q,page)

    const { options, hasMore } = await loadOptions(q, page);
  
    return {
      options,
      hasMore,
  
      additional: {
        page:  page + 1,
      }
    };
  };




  const onStoredata=(e)=>{
    e.preventDefault();
   
    
    if(vinValidation(autoRegistrationData.vin)){

      if(autoRegistrationData.invoice!=null ){
   props.storeAutoData(autoRegistrationData,value);
      }else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please add the invoice.",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });

      }

   }else
   {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "The vin is wrong, sorry we couldn't proceed with your reqeuest.",
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
    });
    }
  
  
  
  }
   

  const handleClick = e => {
    const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
      }
   
  };
 
  // ==========================************================
  // list of options
  // ==========================************================



  // const cityOptions = [
  //   { value: "California", label: "California" },
  //   { value: "New York", label: "New York" },
  // ];

  const autoTypeDefaultValue = [
    { value: "Auto", label: "Auto" }
  ];

  const autoCatagoryDefaultValue = [
    { value: "New", label: "New" }
  ];

  const titleStatus = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "BOS (exportable)", label: "BOS (exportable)" }

  ];


const menuPortal={
  menuPortal: base => ({ ...base, zIndex: 9999 })
}


const onChangeAsyncPaginate=()=>{

}

  const TooltipSearchFor = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Search by the selected value
    </Tooltip>
  );


  const deleteAuto=(id)=>{



Swal.fire({
  title: "Are you sure?",
  text: "You want to delete this auto list",
  icon: "warning",
  width: 400,
  showCancelButton: true,
  timer: 4000,
  timerProgressBar: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!",
}).then((result) => {
  if (result.isConfirmed) {
    props.autoDelete(id);
    }
});



  }


  const latestNotificationCard=()=>{
    let latestNoficationData = props.latestNotification?.data;
  if(latestNoficationData){
if(latestNoficationData?.readStatus==null){

return(
  <MessageBox 
 bodyText={props.latestNotification?.data?.body} 
 postDateRelative={moment(props.latestNotification?.data?.created_at).add(5,'hours').fromNow()}
 postDate={moment(props.latestNotification?.data?.created_at).add(5,'hours').format('dddd DD-MM-YYYY')} 
  title={props.latestNotification?.data?.subject} 
  closeButton={<div className="blockquote-custom-close bg-light-custome shadow-sm" onClick={()=>{

  setCloseMessageBox(true)
  props.NotificationRead(props.latestNotification?.data?.hasNotificationIds)
  }} >Close</div>}
  onShow={closeMessageBox}
  gap={5}
   mediaQueryClass={'handleMedia'}
   width={'calc(100% - 174px)'}

   messageImage={"GA-01.png"}/>
)

  
}else{
  return(
    <></>
  )
}
    }


  }
    

  const loader = () => {
    return <></>;
  };




  return (
    <>
      <div className="beforeFixbar"></div>

       <div className={headerSearch}>
       <form onSubmit={onSearch}>
        <div className="row">
        <div className="col-md-2"></div>

       
          {/* <div className="col-md-2 ">
          <OverlayTrigger
      key={'forSearch'}
      placement={'bottom'}
      delay={{ show: 3000, hide: 0 }}
      overlay={
        TooltipSearchFor
      }
    >

            <div className="selectBox">
              <select className="searchIn" name="">
                <option disabled>Search Catagory</option>
                <option>VIN</option>
                <option>LOT</option>
              </select>
            </div>
           </OverlayTrigger>
          </div> */}
          <div className="col-md-8  searchOuterLayer">
      
      <div className="input-group" >
  <div className="form-outline searchOutline">
   <span><input type="search" name="searchAutos" className="form-control search" onChange={onSearchInputChange} placeholder="Seach by VIN or LOT or Auto Name or Sale Date" autoComplete="off" required/></span> 
   { isCheck.length>0?<span style={{position:'absolute',top:'8px',right:'60px'}}><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={()=>props.selectedAutosDelete(isCheck)}>Delete ({(isCheck.length)})</a></span>:''}
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>

          </div>
          
        </div>
        </form>

        </div>
  {/* ========ends here==========   */}

  {latestNotificationCard()}
  
<div className="verticle-gap"></div>
  {/* ========functional Loader==========   */}

{
  props.functionalLoader?
<Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:''
}
{/*================================== auto list starts here=================================== */}
{ props.loading ? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            /> :

  <div>
  { 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchAutos}</span></p></div>
}
{props.autosListResponse?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}

{props.autosListResponse?.data?.data ? props?.autosListResponse?.data?.data.map((list,index)=>{

return(
<div className="main langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))} key={index}>




<div className="beforeFixbar"></div>


<div className="headerContainer">

<div className="list-container-header"><span style={{position:'absolute', left:'-19px', top:'-4px',zIndex:'1'}}>

{can(currentUserPermissions,'Delete Multiple Autos')?<Checkbox
          key={list.id}
          type="checkbox"
          name={list.id}
          id={list.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.id)}
            />:''
            
            }

            
            </span>
<span>{list.year} {list.make} {list.model} </span>  <span>VIN: {list.vin} </span>  <span>LOT: {list.lot}</span>

</div>

</div>


    <div className="courses-container">

      <div className="course">
           


        {/* <div className="course-preview"> */}
        {/* <div className="CarImage">

        </div> */}

          {/* <h2>2019 HYUN ELANTRA SE</h2>
          <h3>VIN: 5NPLM4AG9MH004853</h3>
          <h3>LOT: 35879722</h3> 
         

        {/* </div> */}
        <div className="course-info">
<div className="row">


{

props.autosListResponse?.images?.filter(image => image.autos_id === list.real_auto_id).map((img,index)=>{

return(
  <div className="col-md-5 col-lg-4 col-xl-3 gx-0  imgCol" key={index}>

<div>
<div className="list-image-container" style={{cursor:'pointer'}} onClick={()=>{
 props.getImages(list.real_auto_id)
setTimeout(() => {
  setImagesModal(true)
},1000)


} }>
<span>
<span style={{position:'absolute', top:'4px', paddingLeft:'4px'}} className="box-shadow"><div className="circle">{img.totalImages}</div></span>

<img alt="autos"  src={`${process.env.REACT_APP_IMAGES_ADRESS}/${img.image_name}`} className=""/>
</span>

</div>
</div>
</div>
)

})
} 





  <div className="col-md-7 col-lg-8 col-xl-9 inCol">


  <div className="row">

            <div className="col-md-4 custom-border"  onClick={() => viewAutoInfo(list.real_auto_id)}>
              <h2>
                <span className="title">{t('key_point_of_loading')}: </span>{" "}
                <span className="description"> {list.point_loading}</span>
              </h2>
              <h2>
                <span className="title">{t('key_destination_port')}:</span>
                <span className="description">{list.port}</span>
              </h2>
              <h2>
                <span className="title">{t('key_container_no')}:</span>{" "}
                <span className="description">{list.container_num_booking}</span>
              </h2>
              <h2>
                <span className="title">{t('key_date')}:</span>{" "}
                <span className="description">{moment(list.created_at).format('DD-MM-YYYY')}</span>
              </h2>

              <h2>
                <span className="title">{t('key_purchase_date')}:</span>{" "}
                <span className="description">{list.purchase_date?moment(list.purchase_date).format('DD-MM-YYYY'):''}</span>
              </h2>
             
              <h2>
                <span className="title">{t('key_arrival_date')}:</span>{" "}
                <span className="description">{list.arrival_date?moment(list.arrival_date).format('DD-MM-YYYY'):''}</span>
              </h2>
              <h2>
                <span className="title">{t('key_unloading_date')}:</span>{" "}
                <span className="description">{list.unloading_date?moment(list.unloading_date).format('DD-MM-YYYY'):''}</span>
              </h2>
            
              <h2>
                <span className="title">{t('key_id')}:</span>{" "}
                <span className="description">{list.real_auto_id}</span>
              </h2>
            </div>
            <div className="col-md-4 custom-border">
            <div  onClick={() => viewAutoInfo(list.real_auto_id)}>
            <h2>
                <span className="title">{t('key_delievered_to_warehouse')}:</span>{" "}
                <span className="description">{list.delivered_warehouse?moment(list.delivered_warehouse).format('DD-MM-YYYY'):''}</span>
              </h2>
              {/* <h2>
                <span className="title">Dock Receipt:</span>{" "}
                <span className="description">not known yet</span>
              </h2> */}
              <h2>
                <span className="title">{t('key_title_status')}:</span>{" "}
                <span className="description">{list.title_status}</span>
              </h2>
              <h2>
                <span className="title">{t('key_title_receieved')}:</span>{" "}
                <span className="description">{list.title_received?moment(list.title_received).format('DD-MM-YYYY'):''}</span>
              </h2>
              <h2>
                <span className="title">{t('key_keys')}:</span>{" "}
                <span className="description">{list.keys}</span>
              </h2>
              {/* <h2>
                <span className="title">{t('key_action')}:</span>{" "}
                <span className="description">nill</span>
              </h2> */}

              </div>
              {can(currentUserPermissions,'View Autos Notes')?
              <h2>
                <span className="title">{t('key_notes')}:</span>{" "}
              
           
                                  <span style={{height:'12px'}}></span>
                                  <button className="notification" onClick={()=>{
                                 setAutosID(list.id)
                                 props.getAutoCommunication(list.id)
                                 setTimeout(()=>{
                                  props.showAutoCommuncationNotesModal()
                                 },200)
                                    
                                    }}>
                                    
                                    <span>{t('key_view_notes')}</span>
                                 { props.autosListResponse?.notesCount.filter(item=>item.autos_id===list.id).map((l,i)=>{return(
                                  <span key={i} className="badge" style={{fontWeight:'normal'}}>{l.totalMessages}</span>
                                 )}) }
         
                                    </button>
            
              </h2>:''}
            </div>

            <div className="col-md-4">
            <div onClick={() => viewAutoInfo(list.real_auto_id)}>

            <h2>
                <span className="title">{t('key_auction')}:</span>{" "}
                <span className="description">{list.auction}</span>
              </h2>
              </div>
            {can(currentUserPermissions,'View Auto Invoice')?
              <h2>
                <span className="title">{t('key_invoice_auto')}:</span>{" "}
                { _.isNull(list.file_name)?'':<span className="description"><span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_FILES_ADRESS}/${list.file_name}`} target={"_blank"}>{list.file_name? list.file_name.substring(0,7).concat('...'):''}</a></span></span>}
              </h2>:''
              }
              <div onClick={() => viewAutoInfo(list.real_auto_id)}>
              <h2>    {list.auto_price?<span>
                <span className="title">{t('key_auto_price')}:</span>{" "}
            <span className="description">{list.auto_price} USD</span></span>:''}
              </h2>
              {/* <h2>
                <span className="title">Paid:</span>{" "}
                <span className="description">12000 usd</span>
              </h2>
              <h2>
                <span className="title">Notes for yourself:</span>{" "}
                <span className="descriptin">
                  is it important
                </span>
              </h2> */}
              <h2>
                <span className="title">{t('key_terminal_notes')}:</span>{" "}
                <span className="description">
{list.terminal_notes}
                </span>
              </h2>
           



              </div>

{can(currentUserPermissions,'View Shipping Invoice')?
              <h2>
                <span className="title">{t('key_shipping_invoice')}:</span>{" "}
                
                <span className="description">
     
           <a onClick={()=>{
            setBillInfo({...billInfo,'autos_id':list.real_auto_id})
            props.getAutoInvoices(list.real_auto_id);
            setTimeout(()=>{
              props.showInvoiceInfoModal()
            },550)
           }
           }><span className="shippingInvoiceText">{t('key_view_invoice')}</span> </a> 

                </span>
              </h2>:''
}

            </div>
            
          </div>



  </div>

{/* badges indicators here */}
<div className="badges-indicators"> <span className={list.auto_catagory==="At the Terminal"?`${list.auto_catagory.substring(7,15)}`:`${list.auto_catagory}`}>{list.auto_catagory==="At the Terminal"?list.auto_catagory.substring(7,8):list.auto_catagory.substring(0,1)}</span></div> 


</div>

          {/* row finishes here */}
          {/* <button className="btn-list"
          >Continue</button> */}
          { hasAnyRole(props.preDataLoadResponse?.currentUserRole,process.env.REACT_APP_SUPER_ADMIN,process.env.REACT_APP_ADMIN ) ?
  <div><span className="created_by">Created by: {list.created_by}</span> {list.for_customer?<span className="for_whom"> For: {list.customerName}</span>:''}</div>:null}

        </div>

        <div className="icon-bar">
        
         <Link to={`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(list.real_auto_id.toString(),'autoListGhulam_123'))}`}  >
            <i className="fa fa-eye"></i>
          </Link>
        
      { can(currentUserPermissions,'Edit Autos')? <a onClick={
          ()=>{
            props.getAutoAdditionalDetails(list.real_auto_id)

             setTimeout(()=>{
              props.showAutoAdditionalModal()

            },550)
            setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,'onId':list.real_auto_id})

          }
        }>
        <i className="fa fa-edit" ></i>                    
        </a>:""
      }
        {/* <a>
          <i class='fas fa-map'></i>
          </a> */}

        {/* note that this is for point of loading updation  */}
         { can(currentUserPermissions,'Edit Autos Placement')? <a  onClick={()=> {
                
                 props.getAutoAdditionalDetails(list.real_auto_id)

            setTimeout(()=>{

              props.showAutoEditModal()

            },550)
            setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,'onId':list.real_auto_id})

          
          }}>
            <i className="fas fa-map-marked-alt">

              
            </i>
          </a>:''}
        
        { can(currentUserPermissions,'Create Shipping Invoice')? <a onClick={()=>{
            
            setBillInfo({...billInfo,'autos_id':list.real_auto_id})

            props.showInvoiceRegModal()
            }}>
          <i className="fas fa-file-invoice-dollar"></i>
                    </a>:''}

       { can(currentUserPermissions,'Delete Autos') ? <a onClick={()=>deleteAuto(list.real_auto_id)}>
            <i className="fa fa-trash"></i>
          </a>:''
       }
        </div>
      </div>
    </div>




</div>
/*================================== auto list ends  here=================================== */

)

}):<NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 

}

</div>
}






      <div className="beforeFixbar"></div>

      
     {can(currentUserPermissions,'Add Vehicle')? <button className="floating-btn" onClick={handleShow}>
       {t('key_add_vehicle')}
      </button>:''}

{ props.autosListResponse?.data?.data.length===0 ? '':

<div>
      {props.autosListResponse?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.autosListResponse?.data.current_page}
              itemsCountPerPage={props.autosListResponse?.data.per_page}
              totalItemsCount={props.autosListResponse?.data.total}
              //   pageRangeDisplayed={5}
              onChange={
                searched==true? handleSearchPages
                :params.warehouse? handlePageChange :(pageNumber) => props.autosList(pageNumber)
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}


      <RightSidebar/>








{/* ========================================================
Modal of add Vehicle
======================================================== */}

      <Modal show={props.openAutoModal} onHide={handleClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add Vehicle </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={onStoredata}>
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
      {can(currentUserPermissions,'Post Autos For Customer') ?   <div className="row">
            <div className="col-md-5 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer for whom you want to post
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={onChange}
        isClearable
      />


            </div>
          </div>:''}
            <div className="row">




            
              <div className="col-md-5 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="VIN#">
                  <Form.Control
                    type="text"
                    name="vin"
                    maxLength="17"
                    placeholder=' '
                    onChange={(e) => {
                      vinDecode(e.target.value);
                    }}
                    isInvalid={props.autoRegistrationErrors?.error?.vin?true:false}
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error?.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

              <div className="col-md-2 mb-3">
              <Select
                  options={autoTypeOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="AutoType"
                  name="autoType"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  setValue={autoRegistrationData.autoType}
                  defaultValue={autoTypeDefaultValue}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                {props.autoRegistrationErrors?.error.autoType?<div className="validation-error">
                    Required !
                  </div>:''}
               
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="model"
                    type="input"
                    name="model"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}

                    value={autoRegistrationData.model||''}
                    required
                  />
                  <label htmlFor="model">Model</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="make"
                    type="input"
                    name="make"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}
                    value={autoRegistrationData.make||''}
                    required
                  />
                  <label htmlFor="make">Make</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="year"
                    type="input"
                    name="year"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}
                    value={autoRegistrationData.year||''}
                    required
                  />
                  <label htmlFor="year">Year</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                   id="color" 
                   type="input" 
                  name="color" 
                  placeholder=' ' 
                  onChange={(e)=>{onInputChange(e)}}
                  required


                  />
                  <label htmlFor="color">Color</label>
                </Form.Floating>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Purchase Date
                </label>

                <DatePicker
                  selected={autoRegistrationData.purchase_date}
                  onChange={(e) => handleDateInputChange(e,"purchase_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Purchase Date"
                  name="purchase_date"
                  required={true}
                  autoComplete="off"            
                  
                      />
              </div>


              <div className="col-md-3 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Auction
                </label>
                <Select
                  options={auctionOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Select Auction"
                  name="auction"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}
                  
                />

{props.autoRegistrationErrors?.error.auction?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-4 mb-2">
                <label htmlFor="city" className="picker-inputs-lebel">
                  City
                </label>
                <Select
                  options={cityOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="City"
                  name="city"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                {props.autoRegistrationErrors?.error.city?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-2 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Buyer No#
                </label>
                <Select
                  options={buyerOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Buyer No"
                  name="buyer_no"
                  required={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                    {props.autoRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label
                  htmlFor="payment_to_auction"
                  className="picker-inputs-lebel"
                >
                  Payment to Auction
                </label>

                <DatePicker
                  selected={autoRegistrationData.payment_to_auction}
                  onChange={(e) => handleDateInputChange(e,"payment_to_auction")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  placeholderText="Payment"
                  name="payment_to_auction"
                  isClearable={true}
                  required={true}
                  autoComplete="off"

                />
              </div>

              <div className="col-md-4 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                
                {props.autoRegistrationErrors?.error.port?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>

              <div className="col-md-4 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                    {props.autoRegistrationErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-2">
                <Form.Floating>
                  <Form.Control 
                   type="input"
                    name="lot"   
                    required
                  placeholder=' '
                  onChange={(e)=>{onInputChange(e)}}
isInvalid={props.autoRegistrationErrors?.error.lot?true:false}
                   />
                  <label htmlFor="model">Lot</label>
                </Form.Floating>
                {props.autoRegistrationErrors?.error?.lot?<div className="validation-error">
                    Please, input a valid lot number, the lot number has already been taken !
                  </div>:''}
              </div>
              <div className="col-md-3 mt-2">
                <Form.Floating>
                  <Form.Control
                   id="model"
                   type="number" 
                  name="auto_price"                
                   placeholder=' '
                   onChange={(e)=>{onInputChange(e)}}

 />
                  <label htmlFor="model">Auto Price</label>
                </Form.Floating>
              </div>
              <div className="col-md-5 mt-2">
                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      To Dismantle
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='dismantle'
                      onChange={(e)=>{handleCheckbox(e)}}
                    />
                  </div>
                </div>

                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      Self Delivered
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name='self_delivered'
                      onChange={(e)=>{handleCheckbox(e)}}

                 
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="customer_notes"
                    rows={4}
                    onChange={onInputChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginTop: "47px" }}>
                  <FileUploader
               
                    handleChange={handleChange}
                    name="file"
                    types={["pdf"]}
                    label={"Upload invoice here"}
                    classes={""}
              
                    children={
                      <div
                        className={
                          dragAndDropSelected === false
                            ? "dragDrop"
                            : "dragDropSelected"
                        }
                      >
                    
                        <span>
                          {dragAndDropText}
                          {dragAndDropSelected === true ? (
                            <span
                              style={{ fontSize: "11px", color: "darkgreen" }}
                            >
                              {" "}
                              selected
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            top: "2px",
                            color: "rgb(10, 80, 134)",
                          }}
                        >
                          Pdf{" "}
                          {dragAndDropSelected === false ? (
                            ""
                          ) : (
                            <i className="fas fa-check"></i>
                          )}
                        </div>
                      </div>
                    }
                  />
                   &nbsp;  &nbsp;

{ autoRegistrationData.invoice!=null? <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
  setAutoRegistrationData({...autoRegistrationData,invoice:null})
  setdragAndDropSelected(false)
  setdragAndDropText("Upload or Drag and Drop your images here")

  }    }>Reset </a>:'' }
                </div>

                {props.autoRegistrationErrors?.error.invoice?<div className="validation-error">
                Please, add a valid pdf file, the pdf file you are uploading has already in our system !
 !
                  </div>:''}


              </div>
            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              ADD
            </button>
          </Modal.Footer>
        </form>
      </Modal>





{/* 
========================================================
Modal of point of loading  Vehicle only
======================================================== 
*/}

<Modal show={props.openAutoEditModal} onHide={handleEditClose} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Auto Placement &nbsp; <i className="fa fa-map-marker"></i> </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={submitChangePointOfLoading} >
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
            className="modal-body"
          >
          <div className="row">
           
           <div className="col-md-12 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.point_of_loading||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                    {props.autoEditErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-12 mb-3">
              <Select
                  options={autoCatagoryOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Auto Placement"
                  name="auto_catagory"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  // setValue={autoRegistrationData.autoType}
                  defaultValue={autoRegistrationAdditionalData?.auto_catagory||''}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {autoAdditionalDataError.auto_catagory_error?<div className="validation-error">
                    Auto placement is required !
                  </div>:''}
               
              </div>
</div>



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
           Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/*
 =================================================================================================================
Additional Information model to upload images and edit information by admin or normal users.
==================================================================================================================
*/}

<Modal show={props.openAutoAdditionalModal} onHide={handleAdditionalClose} size="fullscreen" scrollable={true}>
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Auto Details</Modal.Title>
          <CloseButton variant={"white"} onClick={handleAdditionalClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={onStoreAdditionalData}  encType="multipart/form-data" id="imageForm">
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            style={{
              maxHeight: "calc(110vh - 210px)",
              overflowY: "auto",
           
            }}
            className="modal-body"
          >

<div className="row">


{can(currentUserPermissions,'Post Autos For Customer') ?   <div className="row">
            <div className="col-md-5 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer for whom you want to post
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={editSelector}
        loadOptions={loadPageOptions}
        onChange={setEditSelector}
        isClearable
      />


            </div>
          </div>:''}

              <div className="col-md-5 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="VIN#">
                  <Form.Control
                    type="text"
                    name="vin"
                    maxLength="17"
                    placeholder=' '
                    value={autoRegistrationAdditionalData.vin||''}
                    onChange={(e) => {
                      vinDecodeAdditional(e.target.value);
                    }}
                    isInvalid={props.autoRegistrationErrors?.error.vin?true:false}
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

              <div className="col-md-2 mb-3">
              <Select
                  options={autoTypeOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="AutoType"
                  name="autoType"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  // setValue={autoRegistrationAdditionalData.autoType}
                  defaultValue={autoRegistrationAdditionalData?.autoType||''}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {props.autoEditErrors?.error.autoType?<div className="validation-error">
                    Required !
                  </div>:''}
               
              </div>
            </div>



            <div className="row">
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="model"
                    type="input"
                    name="model"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}

                    value={autoRegistrationAdditionalData.model||''}
                    required
                  />
                  <label htmlFor="model">Model</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="make"
                    type="input"
                    name="make"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}
                    value={autoRegistrationAdditionalData.make||''}
                    required
                  />
                  <label htmlFor="make">Make</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="year"
                    type="input"
                    name="year"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}
                    value={autoRegistrationAdditionalData.year||''}
                    required
                  />
                  <label htmlFor="year">Year</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                   id="color" 
                   type="input" 
                  name="color" 
                  placeholder=' ' 
                  onChange={(e)=>{onInputChangeAdditional(e)}}
                  required
                  value={autoRegistrationAdditionalData.color||''}


                  />
                  <label htmlFor="color">Color</label>
                </Form.Floating>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Purchase Date
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.purchase_date}
                  onChange={(e) => handleDateInputChangeAdditional(e,"purchase_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Purchase Date"
                  name="purchase_date"
                  required={true}
                  autoComplete="off"
                  />
              </div>


              <div className="col-md-3 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Auction
                </label>
                <Select
                  options={auctionOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Select Auction"
                  name="auction"
                  menuPortalTarget={document.body} 
                  defaultValue={autoRegistrationAdditionalData?.auction||''}
                  styles={menuPortal}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}
                  
                />

{props.autoEditErrors?.error.auction?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-4 mb-2">
                <label htmlFor="city" className="picker-inputs-lebel">
                  City
                </label>
                <Select
                  options={cityOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="City"
                  name="city"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.city||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {props.autoEditErrors?.error.city?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-2 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Buyer No#
                </label>
                <Select
                  options={buyerOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Buyer No"
                  name="buyer_no"
                  required={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.buyer_no||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                    {props.autoEditErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label
                  htmlFor="payment_to_auction"
                  className="picker-inputs-lebel"
                >
                  Payment to Auction
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.payment_to_auction}
                  onChange={(e) => handleDateInputChangeAdditional(e,"payment_to_auction")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  placeholderText="Payment"
                  name="payment_to_auction"
                  isClearable={true}
                  required={true}
                  autoComplete="off"


                />
              </div>

              <div className="col-md-4 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.port||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                
                {props.autoEditErrors?.error.port?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>

              <div className="col-md-4 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.point_of_loading||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                    {props.autoEditErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-2">
                <Form.Floating>
                  <Form.Control 
                   type="input"
                    name="lot"   
                    required
                  placeholder=' '
                  value={autoRegistrationAdditionalData.lot||''}
                  onChange={(e)=>{onInputChangeAdditional(e)}}
isInvalid={props.autoEditErrors?.error.lot?true:false}
                   />
                  <label htmlFor="model">Lot</label>
                </Form.Floating>
                {props.autoEditErrors?.error?.lot?<div className="validation-error">
                    Please, input a valid lot number, the lot number has already been taken !
                  </div>:''}
              </div>
              <div className="col-md-3 mt-2">
                <Form.Floating>
                  <Form.Control
                   id="model"
                   type="number" 
                  name="auto_price"  
                  value={autoRegistrationAdditionalData.auto_price||''}
              
                   placeholder=' '
                   onChange={(e)=>{onInputChangeAdditional(e)}}

 />
                  <label htmlFor="model">Auto Price</label>
                </Form.Floating>
              </div>
              <div className="col-md-5 mt-2">
                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      To Dismantle
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='dismantle'
                      onChange={(e)=>{handleCheckboxAddtional(e)}}

                      defaultChecked={props.getAdditionalRegDataSuccess?.data.to_dismantle=='true' ? true : false||'' }

                    />
                  </div>
                </div>

                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      Self Delivered
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='self_delivered'
                      onChange={(e)=>{handleCheckboxAddtional(e)}}
                      defaultChecked={props.getAdditionalRegDataSuccess?.data.self_delivered=='true' ? true : false||'' }

                 
                    />
                  </div>
                </div>
              </div>
            </div>






            <div className="row ">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="customer_notes"
                    rows={4}
                    value={autoRegistrationAdditionalData?.customer_notes||''}

                    onChange={onInputChangeAdditional}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginTop: "30px" }}>
                  <FileUploader
               
                    handleChange={handleChangeAdditional}
                    name="file"
                    types={["pdf"]}
                    label={"Upload invoice here"}
                    classes={""}
              
                    children={
                      <div
                        className={
                          dragAndDropAdditionalInvoiceSelected === false
                            ? "dragDrop"
                            : "dragDropSelected"
                        }
                      >
                    
                        <span>
                          {dragAndDropAdditionalInvoiceText}
                          {dragAndDropAdditionalInvoiceSelected  === true ? (
                            <span
                              style={{ fontSize: "11px", color: "darkgreen" }}
                            >
                              {" "}
                              selected
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            top: "2px",
                            color: "rgb(10, 80, 134)",
                          }}
                        >
                          Pdf{" "}
                          {dragAndDropAdditionalInvoiceSelected === false ? (
                            ""
                          ) : (
                            <i className="fas fa-check"></i>
                          )}
                        </div>
                      </div>
                    }
                  />
                   &nbsp;  &nbsp;

{ autoRegistrationAdditionalData.invoice!=null? <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{


setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,invoice:null})
setdragAndDropAdditionalInvoiceSelected(false)
                    setdragAndDropAdditionalInvoiceText("Upload or Drag and Drop your invoice here")


  }    }>Reset </a>:'' }
                </div>

                {props.autoEditErrors?.error.invoice?<div className="validation-error">
                Please, add a valid pdf file, the pdf file you are uploading has already in our system !
 !
                  </div>:''}


              </div>
            </div>

<div className="row">
<div className="col-md-6"></div>


{
  <div className="col-md-4">

  <div className="form-label picker-inputs-lebel" >
                    invoice of this auto
                  </div>
                  <a style={{textDecoration:'none',color:'white'}} href={`${process.env.REACT_APP_FILES_ADRESS}/${props?.getAdditionalRegDataSuccess?.invoice[0]?.file_name}`} target={"_blank"}>
  <div className="card" style={{background:'rgba(22, 112, 207, 1)', color:'white'}}>

  <div className="container">
    <div style={{fontSize:'15px'}}>
   <div style={{position:'absolute', right:'12px'}}><i className="fas fa-file-pdf"></i></div> { props?.getAdditionalRegDataSuccess?.invoice[0]?.file_name}
</div>

  </div>
</div></a>

  </div>
}






  
</div>




            <div className="pt-3"></div>


<div className="header row">
<div className="col">Admins Section</div>


      
      </div>
<div className="pt-3"></div>







     {/* =================================================
         admin registration portion starts from here     
     =================================================      */}

            <div className="row">
             
              <div className="col-md-5 col-sm-3 col-lg-4 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="containerNoBooking"
                    type="input"
                    name="containerNoBooking"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}

                    value={autoRegistrationAdditionalData.containerNoBooking||''}
               
                  />
                  <label htmlFor="containerNoBooking">Container No Booking</label>
                </Form.Floating>
              </div>
           
             

              <div className="col-md-4 mb-3">
              <Select
                  options={autoCatagoryOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Auto Placement"
                  name="auto_catagory"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  // setValue={autoRegistrationData.autoType}
                  defaultValue={autoRegistrationAdditionalData?.auto_catagory||''}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {autoAdditionalDataError.auto_catagory_error?<div className="validation-error">
                    Auto placement is required !
                  </div>:''}
               
              </div>
            </div>
           

            <div className="row">
              <div className="col-md-3  mb-2">
                <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Promised Picked up
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.pickUp}
                  onChange={(e) => handleDateInputChangeAdditional(e,"pickUp")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select pick up"
                  name="pickUp"
                  // required={true}
                  autoComplete="off"
                />

{props.autoRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>


              <div className="col-md-3 mb-2">
              <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Delivered to Warehouse
                </label>

              <DatePicker
                  selected={autoRegistrationAdditionalData.toWarehouse}
                  onChange={(e) => handleDateInputChangeAdditional(e,"toWarehouse")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-Y"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select to warehouse"
                  name="toWarehouse"
                  // required={true}
                  autoComplete="off"
                />
 {props.autoRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-3 mb-2">
                <label htmlFor="city" className="picker-inputs-lebel">
                  Arrival Date
                </label>
                <DatePicker
                  selected={autoRegistrationAdditionalData.arrival_date}
                  onChange={(e) => handleDateInputChangeAdditional(e,"arrival_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select arrival date"
                  name="arrival_date"
                  // required={true}
                  autoComplete="off"
                />

                 {props.autoRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>
              <div className="col-md-3 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Unloading date
                </label>
                <DatePicker
                  selected={autoRegistrationAdditionalData.unloading_date}
                  onChange={(e) => handleDateInputChangeAdditional(e,"unloading_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select unloading date"
                  name="unloading_date"
                  // required={true}
                  autoComplete="off"
                />
                    {props.autoRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">

            <div className="col-md-3 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Title Status
                </label>
                <Select
                  options={titleStatus}
                  theme={theme}
                  placeholder="Title Status"
                  name="title_status"
                  isClearable={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}
                  
                  defaultValue={autoRegistrationAdditionalData?.title_status}

                />
                
                {props.autoRegistrationErrors?.error.port?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>





              <div className="col-md-3 mb-2">
                <label
                  htmlFor="title_received"
                  className="picker-inputs-lebel"
                >
                  Title Received
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.title_received}
                  popperClassName="popper"
                  onChange={(e) => handleDateInputChangeAdditional(e,"title_received")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  placeholderText="Title Received"
                  name="title_received"
                  isClearable={true}
                  autoComplete="off"


                />
              </div>

              

              <div className="col-md-6 mt-4">
              <div className="row mt-3">
                  <div className="col-md-2">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      Keys
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='keys'
                      defaultChecked={props.getAdditionalRegDataSuccess?.data.keys=='true' ? true : false||'' }
                      onChange={(e)=>{handleCheckboxAddtional(e)}}
                    />
                  </div>
                </div>
              </div>
            </div>

           

            <div className="row ">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Terminal Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="terminal_notes"
                    rows={4}
                    style={{height:'40px'}}
                    value={autoRegistrationAdditionalData.terminal_notes||''}
                    onChange={onInputChangeAdditional}
                  ></textarea>
                </div>
              </div>
              
              <div className="col-md-6">
              <div className="pt-3">
              { props.uploadPercentage>0 && autoRegistrationAdditionalData.images.length>0 ? <ProgressBar animated now={100} label={`${props.uploadPercentage}%`}/>:''}
</div>
           {  can(currentUserPermissions,'Edit Auto Images')  ? <div style={{ marginTop: "10px" }}>
      
           
                  <FileUploader
               
                    handleChange={handleChangeImages}
                    name="file"
                    types={["jpeg","jpg"]}
                    label={"Upload invoice here"}
                    classes={""}
                    multiple
                    children={
                      <div
                        className={
                          dragAndDropSelectedImages === false
                            ? "dragDrop"
                            : "dragDropSelected"
                        }
                      >
                    
                        <span>
                          {dragAndDropTextImages}
                          {dragAndDropSelectedImages === true ? (
                            <span
                              style={{ fontSize: "11px", color: "darkgreen" }}
                            >
                              {" "}
                              selected
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            top: "2px",
                            color: "rgb(10, 80, 134)",
                          }}
                        >
                          jpeg, jpg{" "}
                          {dragAndDropSelectedImages === false ? (
                            ""
                          ) : (
                            <i class="fas fa-check"></i>
                          )}
                        </div>
                      </div>
                    }
                  />
                  &nbsp;  &nbsp;

                  { autoRegistrationAdditionalData.images.length>0? <><a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
                    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,images:[]})
                    setdragAndDropSelectedImages(false)
                    setdragAndDropTextImages("Upload or Drag and Drop your images here")
               
                    }    }>Reset Images</a>  &nbsp;   <a className="add-images-btn" onClick={()=>uploadImages()}>Add Images</a>   </> :'' }
                </div>:''

           }

              </div>

           { can(currentUserPermissions,'Edit Auto Images')  ?  <ImagesGrid id={autoRegistrationAdditionalData.onId}/>:''}
          


            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add Details
            </button>
          </Modal.Footer>
        </form>
      </Modal>








{/* ========================================================
model for images
======================================================== */}

<Modal show={imagesModal} onHide={()=>{setImagesModal(false)}} size="fullscreen"  className="special_modal" centered >
        {/* <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">System Details</Modal.Title>
          <CloseButton variant={"white"} onClick={handleAdditionalClose}></CloseButton>
        </Modal.Header> */}

          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}
         <div style={{position:'absolute',right:'5%', top:'1%', zIndex:'99999', backgroundColor:'white'}} className="circle2"> <CloseButton  style={{fontSize:'12px', color:'black'}} onClick={()=>{setImagesModal(false)}}></CloseButton></div>

          <Modal.Body
            className="modal-body"
          >
            <div className="row">
             
              <div className="slider-container">

              <ImagesCarousel/>


              </div>


            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

 
      </Modal>









{/* 
========================================================
Modal for invoice information registration
======================================================== 
*/}

<Modal show={props.openInvoiceReg} onHide={handleInvoiceRegClose} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Create Shipping Invoice </Modal.Title>
          <CloseButton variant={"white"} onClick={handleInvoiceRegClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={submitAutoInvoice} >
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
            className="modal-body"
          >
          <div className="row">
           

         



           <div className="col-md-12 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                Invoice Type
                </label>
                <Select
                  options={billCatagory}
                  theme={theme}
                  placeholder="invoice type"
                  name="invoice_catagory"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
            

                  onChange={(e,selector) => handleBillSelector(e,selector)}

                />
                    {props.autoEditErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>



              <div className="col-md-12 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                Bill To
                </label>
                <Select
                  options={billersOptions}
                  theme={theme}
                  placeholder="bill to"
                  name="bill_to"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
            

                  onChange={(e,selector) => handleBillSelector(e,selector)}

                />
                    {props.autoEditErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>



          <div className="col-md-12 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="amount"
                    type="number"
                    name="amount"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeBill(e)}}
required
                    value={billInfo.amount||''}
                    autoComplete="off"
                    
                  />
                  <label htmlFor="amount">Payment</label>
                </Form.Floating>
              </div>


              {/* <div className="col-md-12 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="bill_to"
                    type="input"
                    name="bill_to"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeBill(e)}}

                    value={billInfo.bill_to||''}
                    autoComplete="off"
                    
                  />
                  <label htmlFor="amount">Bill To</label>
                </Form.Floating>
              </div> */}



              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Invoice Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="notes"
                  
                    onChange={onInputChangeBill}
                  ></textarea>
                </div>
              </div>
            
</div>



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
         Create
            </button>
          </Modal.Footer>
        </form>
      </Modal>








{/* 
========================================================
Modal for invoice view information
======================================================== 
*/}




 {/* ========================================================
Modal to view invoice information
======================================================== */}

<Modal show={props.openInvoiceInfo} onHide={handleInvoiceInfoClose} size="fullscreen">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Invoice Information{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleInvoiceInfoClose}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >

{props.noInvoicePresent? <NodataFound notFoundText={"No shipping invoice has been generated by the administration yet, please wait until they create one."} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="black" notFoundColor="#eb9e23"/> 

:
<BillView autoID={billInfo.autos_id} currentPage={props?.currentPage}/>
}

          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button className="btn-cancel" onClick={handleInvoiceInfoClose}>
            Close
          </button>

          {/* <button className="btn-submit" >Confirm</button> */}
        </Modal.Footer>
      </Modal>







 {/* ========================================================
Modal to view auto communication
======================================================== */}

<Modal show={props.openAutoCommuncation} onHide={handleCommunicationModalClose} size="fullscreen">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            {t('key_auto_communication')}{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleCommunicationModalClose}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >

<AutosCommunication AutoId={autosId}/>

          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button className="btn-cancel" onClick={handleCommunicationModalClose}>
            {t('key_close')}
          </button>

          {/* <button className="btn-submit" >Confirm</button> */}
        </Modal.Footer>
      </Modal>










    </>
  );
}

const mapStateToProps = (state) => {
  const {preDataLoadResponse } = state.preDataHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  const {latestNotification,lastNotification,notificationsDeletedSuccess } = state.NotificationHandler;

  const {searchAutos,loading,autoRegistrationSuccess,autoAdditionalRegistrationSuccess, autoRegistrationErrors,openAutoModal,cleanValues,autosListResponse,openAutoEditModal,openAutoAdditionalModal,currentPage,uploadPercentage,getAdditionalRegDataSuccess,imagesDeletionSuccess,pointOfLoadingChangeSuccess,autoDeleted,selectedAutosDeleted,selectedArray,openAutoCommuncation,usersSelectorListResponse,cleanAdditionalValues,autosImagesUploadResponse,functionalLoader } = state.AutosHandler;
const {openInvoiceReg,openInvoiceInfo,noInvoicePresent} = state.AutoInvoiceHandler;

  return {
    preDataLoadResponse:preDataLoadResponse,
    latestNotification:latestNotification,
    lastNotification:lastNotification,
    searchAutos:searchAutos,
    SelectorsFetch:SelectorsFetch,
    autoRegistrationErrors:autoRegistrationErrors,
    openAutoModal:openAutoModal,
    openAutoEditModal:openAutoEditModal,
    cleanValues:cleanValues,
    loading:loading,
    autosListResponse:autosListResponse,
    currentPage:currentPage,
    openAutoAdditionalModal:openAutoAdditionalModal,
    autoRegistrationSuccess:autoRegistrationSuccess,
    uploadPercentage:uploadPercentage,
    autoAdditionalRegistrationSuccess:autoAdditionalRegistrationSuccess,
    getAdditionalRegDataSuccess:getAdditionalRegDataSuccess,
    imagesDeletionSuccess:imagesDeletionSuccess,
    pointOfLoadingChangeSuccess:pointOfLoadingChangeSuccess,
    autoDeleted:autoDeleted,
    selectedAutosDeleted:selectedAutosDeleted,
    selectedArray:selectedArray,
    openInvoiceInfo:openInvoiceInfo,
    openInvoiceReg:openInvoiceReg,
    noInvoicePresent:noInvoicePresent,
    openAutoCommuncation:openAutoCommuncation,
    usersSelectorListResponse:usersSelectorListResponse,
    cleanAdditionalValues:cleanAdditionalValues,
    autosImagesUploadResponse:autosImagesUploadResponse,
    functionalLoader:functionalLoader,
    notificationsDeletedSuccess:notificationsDeletedSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    getSelectors: () => dispatch(getSelectors()),
    storeAutoData:(autoData,customerID)=>dispatch(storeAutoData(autoData,customerID)),
    showAutoRegModal:()=>dispatch(showAutoRegModal()),
    hideAutoRegModal:()=>dispatch(hideAutoRegModal()),
    showAutoEditModal:()=>dispatch(showAutoEditModal()),
    hideAutoEditModal:()=>dispatch(hideAutoEditModal()),

    showInvoiceInfoModal:()=>dispatch(showInvoiceInfoModal()),
    hideInvoiceInfoModal:()=>dispatch(hideInvoiceInfoModal()),

    showInvoiceRegModal:()=>dispatch(showInvoiceRegModal()),
    hideInvoiceRegModal:()=>dispatch(hideInvoiceRegModal()),

    showAutoCommuncationNotesModal:()=>dispatch(showAutoCommuncationNotesModal()),
    hideAutoCommuncationNotesModal:()=>dispatch(hideAutoCommuncationNotesModal()),


    showAutoAdditionalModal:()=>dispatch(showAutoAdditionalModal()),
    hideAutoAdditionalModal:()=>dispatch(hideAutoAdditionalModal()),
    autosList:(pageNumber)=>dispatch(autosList(pageNumber)),
    autoAdditionalRegistration:(autoAdditionalData,pageNumber,selector)=>dispatch(autoAdditionalRegistration(autoAdditionalData,pageNumber,selector)),
    resetProgressBar:()=>dispatch(resetProgressBar()),
    getImages:(id)=>dispatch(getImages(id)),
    getAutoAdditionalDetails:(id)=>dispatch(getAutoAdditionalDetails(id)),
    changePointOfLoading:(id,loadingPoint,currentPage)=>dispatch(changePointOfLoading(id,loadingPoint,currentPage)),
    filterListOfWarhouses:(warehouse,placement,pageNumber)=>dispatch(filterListForWarhouses(warehouse,placement,pageNumber)),
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchAutosList:(querry,currentPage)=>dispatch(searchAutosList(querry,currentPage)),
    autoDelete:(id)=>dispatch(autoDelete(id)),
    selectedAutosDelete:(ids)=>dispatch(selectedAutosDelete(ids)),
    registerAutoInvoice:(data)=>dispatch(registerAutoInvoice(data)),
    getAutoInvoices:(id)=>dispatch(getAutoInvoices(id)),
    usersSelectorList:(query,page)=>dispatch(usersSelectorList(query,page)),
    storeImagesAutos:(autosData)=>dispatch(storeImagesAutos(autosData)),
    getAutoCommunication:(AutoId)=>dispatch(getAutoCommunication(AutoId)),
    getLatestNotificationDetails:()=>dispatch(getLatestNotificationDetails()),
    NotificationRead:(id)=>dispatch(NotificationRead(id)),

  };
};


export default connect(mapStateToProps,mapDispatchToProps)(AutosList);
