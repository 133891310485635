import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import Loader from "../../elements/Loader";


function ViewAutoCarousel(props) {
  return (
  
    <>
    <div className="carousel-outer-view-auto">

{ props.autoImages?
    <Carousel showArrows={true} useKeyboardArrows stopOnHover swipeable emulateTouch >
  { props.autoImages?.map((img,index)=>{

return (


  <div key={index}>
  
        <img className="carousel-images" src={`${process.env.REACT_APP_IMAGES_ADRESS}/${img.image_name}`} />

    </div>


)



  })
     
  
  }
  
  
    


</Carousel>
: <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"300px"}
            />
}
</div>
</>


  )
}


const mapStateToProps = (state) => {

    const {loading,getImagesSuccess} = state.AutosHandler;
  
  
    return {
   loading:loading,
   getImagesSuccess:getImagesSuccess,
      
    
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
  
    
    
  
    };
  };
  
  





export default connect(mapStateToProps,mapDispatchToProps) (ViewAutoCarousel)