


export const can = (currentUserPermissions, permission) => {
    return (currentUserPermissions || []).includes(permission);
};

export const hasRole = (currentUserRole, role) => {
    return (currentUserRole || []).includes(role);
};

export const hasAnyRole = (currentUserRoles, ...roles) => {
    return roles.some(role => currentUserRoles.includes(role));
};

