import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {consigneeUsersSelector,consigneeUpdate,hideconsigneeModal,showconsigneeModal,showEditconsigneeModal,hideEditconsigneeModal,getconsignees,consigneeEdit,inputHandler,registerconsignee, consigneeDelete} from "../../../redux/actions";
import { AsyncPaginate } from "react-select-async-paginate";

function Consignees(props) {


// const [addconsigneeData,setAddconsigneeData] = useState({name:''});
const [consigneeData,setconsigneeData] = useState({name:'',onId:'',address:'',country:'',phone:'',email:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);
const [value, onChange] = useState([]);
const [editValue, setEditValue] = useState([]);


  useEffect(() => {
    props.getconsignees(props.currentPage);
    if(props.registerconsigneeSuccess){
      onChange(null)
    }
  }, [props.registerconsigneeSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setconsigneeData({...consigneeData,
  'name':props.editDetails?.data?.consignee_name||'',
  'address':props.editDetails?.data?.address||'',
  'country':props.editDetails?.data?.country||'',
  'phone':props.editDetails?.data?.phone||'',
  'email':props.editDetails?.data?.email||'',


})
setEditValue({label:props.editDetails?.data?.customerName, value:props.editDetails?.data?.userId})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setconsigneeData({...consigneeData, [e.target.name]:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hideconsigneeModal()

  };
  const handleShow = () => {

    props.showconsigneeModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditconsigneeModal();

  };

  const handleEditShow=()=>{
    props.showEditconsigneeModal()

  }





  const loadOptions = async (search, page) => {
    // await sleep(1000);
    const optionsPerPage = 6;

      const responseJSON = await props?.consigneeCustomersSelector?.data;
    // console.log(responseJSON.data)
    // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)

      let options; 
      if(responseJSON?.data){
          options = responseJSON.data.map(function (data) {
          return { value: data.id, label: `${data.name}` }
        })
      }
      // inshallah will iterate it from back end
  // console.log(options)
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
  
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = responseJSON.last_page > page;

    const slicedOptions = filteredOptions.slice(
      (page - 1) * optionsPerPage,
      page * optionsPerPage
    );
    // console.log(page)
    return {
      options: slicedOptions,
      hasMore
    };

  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    props.consigneeUsersSelector(q)

    const { options, hasMore } = await loadOptions(q, page);
  
    return {
      options,
      hasMore,
  
      additional: {
        page:  page + 1,
      }
    };
  };


  const defaultAdditional = {
    page: 1
  };
  
  




const deleteconsignee = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this consignee",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.consigneeDelete(id, props.currentPage);
    }
  });
};





const submitconsignee = (e) => {
  e.preventDefault();
  let name=props?.name;
  if(name){
    props.registerconsignee(name,
      value?.value||"",
      props?.address,
      props?.country,
      props?.phone,
      props?.email
    )
  }
}


const Updateconsignee = (e) => {
  e.preventDefault();
  let name=consigneeData.name;
  let id=consigneeData.onId;
  let address=consigneeData.address;
  let country=consigneeData.country;
  let phone=consigneeData.phone;
  let email=consigneeData.email;


  let currentPage=props.consigneesList?.data?.current_page;
  props.consigneeUpdate(id,
    name,
    editValue?.value||"",
  currentPage,
  address,
  country,
  phone,
  email
  
  );
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7"> 
        
     <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add consignee</a></div> 
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.consigneesList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-4 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.consignee_name}</p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a onClick={
                    ()=>{
                      setconsigneeData({...consigneeData,'onId':list.id})
                      props.consigneeEdit(list.id)
                      setTimeout(()=>{
                        handleEditShow()

                      },190)

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a  onClick={()=>deleteconsignee(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.consigneesList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.consigneesList?.data?.current_page}
              itemsCountPerPage={props.consigneesList?.data?.per_page}
              totalItemsCount={props.consigneesList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getconsignees(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New consignee </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitconsignee}>
          <Modal.Body >
            <div className="row">

            <div className="col-md-12 mb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer to whom you want to add this consignee
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={onChange}
        // closeMenuOnSelect={false}
        isClearable
      />


            </div>

              <div className="col-md-12 mb-3">
                <FloatingLabel label="consignee Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.consigneeRegisterErrors?.error?.consignee_name?true:false}
                  />

                </FloatingLabel>

                {props.consigneeRegisterErrors?.error?.consignee_name ? (
                  <div className="validation-error">
                    The consignee name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>





              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={props?.address}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Country">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="country"
                    value={props?.country}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={props?.phone}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={props?.email}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>




            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit consignee </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={Updateconsignee}>
          <Modal.Body >
            <div className="row">

            <div className="col-md-12 mb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer to whom you want to add this consignee
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={editValue}
        loadOptions={loadPageOptions}
        onChange={setEditValue}
        // closeMenuOnSelect={false}
        isClearable
      />


            </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="consignee Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={consigneeData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.consigneeUpdateErrors?.error?.consignee_name ?true:false}
                  />

                </FloatingLabel>

                {props.consigneeUpdateErrors?.error?.consignee_name ? (
                  <div className="validation-error">
                    The consignee name already exists, you can't update it with this consignee name !
                  </div>
                ) : (
                  ""
                )}

              </div>



              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={consigneeData.email||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>



              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={consigneeData.phone||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Country">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="country"
                    value={consigneeData.country||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>



              <div className="col-md-12 mb-3">
                <FloatingLabel label="Consignee Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={consigneeData.address||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>


              

              
              




            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {address,country,phone,email,consigneeCustomersSelector,loading,consigneesList,showWarModal,showEditWarModal,name,registerconsigneeSuccess,consigneeRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,consigneeUpdateErrors} = state.SettingsConsigneeHandler;

  return {
    loading: loading,
    name:name,
    address:address,
    phone:phone,
    country:country,
    email:email,
    consigneeRegisterErrors:consigneeRegisterErrors,
    currentPage:currentPage,
    consigneesList:consigneesList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerconsigneeSuccess:registerconsigneeSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    consigneeUpdateErrors:consigneeUpdateErrors,
    consigneeCustomersSelector:consigneeCustomersSelector
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
 
      getconsignees:(currentPage)=>dispatch(getconsignees(currentPage)),
      showconsigneeModal:()=>dispatch(showconsigneeModal()),
      hideconsigneeModal:()=>dispatch(hideconsigneeModal()),
      showEditconsigneeModal:()=>dispatch(showEditconsigneeModal()),
      hideEditconsigneeModal:()=>dispatch(hideEditconsigneeModal()),
      registerconsignee:(name,id,address,country,phone,email)=>dispatch(registerconsignee(name,id,address,country,phone,email)),
      consigneeEdit:(id)=>dispatch(consigneeEdit(id)),
      consigneeUpdate:(id,name,customerId,currentPage,address,country,phone,email)=>dispatch(consigneeUpdate(id,name,customerId,currentPage,address,country,phone,email)),
      consigneeDelete:(id,currentPage)=>dispatch(consigneeDelete(id,currentPage)),
      consigneeUsersSelector:(q)=>dispatch(consigneeUsersSelector(q)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Consignees);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================