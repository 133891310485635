import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import {rolePicker, rolesList, showRoleModal,hideRoleModal,inputHandler,registerRole,showRolePermissions, showEditRoleModal, hideEditRoleModal, roleDelete,roleUpdate,preDataLoad } from "../../../redux/actions";
import Checkbox from '../../elements/Checkbox';

function RolesList(props) {


const [permissions,setPermissions] = useState([]);
const [roleData,setRoleData] = useState({name:''});
const [viewModal,setViewModal] = useState(false);
const [checkedState, setCheckedState] = useState([]);
const [roleNameEdit,setRoleNameEdit] = useState({name:''});
const checkBoxRef = useRef([]);
const [isChecked, setIsCheck] = useState([]);
const [isCheckAll, setIsCheckAll] = useState(false);   

  useEffect(() => {
    props.rolesList(props.currentPage);
    if(props.roleRegisterSuccess||props.roleDeletedResponse||props.showPermissions)
    props.rolePicker();


  }, [props.roleRegisterSuccess,props.roleDeletedResponse,props.showPermissions]);



  let values=props.showPermissions?.rolePermissions;



    useEffect(()=>{

      setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
      selectedCheckboxes();
  
  
  },[props.showPermissions] )

// ============================================
// selected checkboxes values from database
// ============================================
  const selectedCheckboxes=()=>{


    const newArray= values?.map((item,index)=>{
   
      return item.id.toString();
       
          })
      
     setCheckedState(newArray);
      

  }


  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };


// ============================================
// for edit checkboxes 
// ============================================
  const handleOnChangeCheckbox = (event,index) => {
    // const items = checkedState.filter(item => item !== event.target.value);
    var permissions_array = [...checkedState];
  if (event.target.checked) {
    permissions_array = [...checkedState, event.target.value];
  } else {
   
    permissions_array.splice(checkedState.indexOf(event.target.value), 1);
  }
  setCheckedState(permissions_array);
  // console.log(checkedState)

  }




  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setRoleNameEdit({ name:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
    setIsCheck([])
    setIsCheckAll(false)

    props.hideRoleModal()

  };
  const handleShow = () => {
    setIsCheck([])
    props.showRoleModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    setCheckedState([]);
    props.hideEditRoleModal();

  };

  const handleEditShow=(id)=>{
    props.showRolePermissions(id);
    props.showEditRoleModal()
    
  }

// ============================================
// show information modal of roles
// ============================================
const viewModalClose=()=>{
setViewModal(false);

}

const viewModalShow=(id)=>{
  props.showRolePermissions(id);
  setViewModal(true);
  
  }

//   const handleCheckBoxChange=(event) => {
//     const target = event.target;
//     var value = target.value;
    
//     if(target.checked){
//       permissions[value] = value;   
//       const new_arr = _.compact(permissions)
//       console.log(new_arr)
//     }else{
//       permissions.splice(value, 1);
//       const new_arr = _.compact(permissions)

//       console.log(new_arr)

//     }
    
// }



const deleteRole = (role_id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this role",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.roleDelete(role_id, props.currentPage);
    }
  });
};





const handleCheck = (event) => {
  var permissions_array = [...permissions];
  if (event.target.checked) {
    permissions_array = [...permissions, event.target.value];
  
  } else {
   
    permissions_array.splice(permissions.indexOf(event.target.value), 1);
  }
  setPermissions(permissions_array);
  // console.log(permissions)
};


const submitRole = (e) => {
  e.preventDefault();
  let name=props?.name;
  if(!_.isEmpty(isChecked)){
props.registerRole(name,isChecked);
  }else{

    Swal.fire({
      icon: "info",
      title: "Oops...",
      text: 'Please select a single or multiple permissions for this role !',
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
      background: "ebeced",
    });
  }
}


const submitRoleUpdate = (e) => {
  e.preventDefault();
  let id=props.showPermissions?.role.id;
  let name=roleNameEdit.name;

  let currentPage=props.rolesListSuccessResponse?.roles?.current_page;
  if(!_.isEmpty(checkedState)){
props.roleUpdate(id,name,checkedState,currentPage);
  }else{

    Swal.fire({
      icon: "info",
      title: "Oops...",
      text: 'Please select a single or multiple permissions for this role to update !',
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
      background: "ebeced",
    });
  }
}






const handleSelectAll = e => {
  setIsCheckAll(!isCheckAll);
  setIsCheck(props.rolesListSuccessResponse?.permissions.map(li => li.id));
  if (isCheckAll) {
    setIsCheck([]);
  }
};



const handleClickCheck = e => {
  const { id, checked } = e.target;
  setIsCheck([...isChecked, id]);
    if (!checked) {
      setIsCheck(isChecked.filter((item) => item !== id));
    }
 
};



  return (
    <>
      <div>
    
      <div className="row">
      <div className="col-md-7">   
      <a onClick={handleShow} className="btn btn-primary">Add Role</a>
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.rolesListSuccessResponse &&
            props.rolesListSuccessResponse.roles.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-3 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="bg-white rounded shadow-sm py-2 px-4 list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <h6 className="mt-2">{list.name}</h6>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                <li className="list-inline-item">
                  <a href="#" onClick={()=>viewModalShow(list.id)} className="icons-link">
                    <i className="fa fa-eye eye"></i>
                  </a>
                </li>
            

                <li className="list-inline-item">
                  <a href="#" onClick={()=>{
                    setTimeout(()=>{
                      handleEditShow(list.id)

                    },550)
                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
           
                {list.name==="Super-Admin" || list.name==="Admin" ?"":
                <li className="list-inline-item">
                  <a href="#"  onClick={()=>deleteRole(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>}
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.rolesListSuccessResponse && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.rolesListSuccessResponse.roles.current_page}
              itemsCountPerPage={props.rolesListSuccessResponse.roles.per_page}
              totalItemsCount={props.rolesListSuccessResponse.roles.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.rolesList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showModal} onHide={handleClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New Role </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitRole}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-6 mb-3">
                <FloatingLabel label="Role Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.roleRegisterErrors?.error?.name?true:false}
                  />

                </FloatingLabel>

                {props.roleRegisterErrors?.error?.name ? (
                  <div className="validation-error">
                    The role name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>
              <div className="col-md-2"></div>
              <div className="col-md-4" > 
              <span style={{position:'relative',top:'-1px'}}>Select All</span> &nbsp;
        <span className="ml-1">
              <Checkbox
        type="checkbox"
        name="selectAll"
        id="selectAll"
        handleClick={handleSelectAll}
        isChecked={isCheckAll||false}
      />   

</span>
       </div>
            </div>
     <div className="row border modal-body"  style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}>

<div className="lineInPermissions"><span>SUPER ADMIN PERMISSIONS</span></div>

         { props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag==="super-admin").map((list,index) => (

    <div  className="mt-3 col-md-3" key={index}>
      {/* <Form.Check 
        type={'checkbox'}
        name="permission"
        id={list.name}
        label={list.name.charAt(0).toUpperCase()+list.name.slice(1)}
        value={list.id}
        onChange={handleCheck}
     
      /> */}


<Checkbox
 key={list.id}
 type="checkbox"
 name={list.id}
 id={list.id}
 handleClick={handleClickCheck}
 isChecked={isChecked.find(li=>li===list.id)}
/> <span style={{position:'relative',top:'-3px',fontSize:'12px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>

    </div>
  ))}



  <div className="pt-2"></div>
<div className="lineInPermissions"><span>AUTOS PERMISSIONS</span></div>

{ props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag==="Autos").map((list,index) => (

<div  className="mt-3 col-md-3" key={index}>
  {/* <Form.Check 
    type={'checkbox'}
    name="permission"
    id={list.name}
    label={list.name.charAt(0).toUpperCase()+list.name.slice(1)}
    value={list.id}
    onChange={handleCheck}
 
  /> */}


<Checkbox
key={list.id}
type="checkbox"
name={list.id}
id={list.id}
handleClick={handleClickCheck}
isChecked={isChecked.find(li=>li===list.id)}
/> <span style={{position:'relative',top:'-3px',fontSize:'12px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>

</div>
))}






<div className="pt-2"></div>
<div className="lineInPermissions"><span>LOADING LISTS PERMISSIONS</span></div>

{ props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag==="LoadingList").map((list,index) => (

<div  className="mt-3 col-md-3" key={index}>
  {/* <Form.Check 
    type={'checkbox'}
    name="permission"
    id={list.name}
    label={list.name.charAt(0).toUpperCase()+list.name.slice(1)}
    value={list.id}
    onChange={handleCheck}
 
  /> */}


<Checkbox
key={list.id}
type="checkbox"
name={list.id}
id={list.id}
handleClick={handleClickCheck}
isChecked={isChecked.find(li=>li===list.id)}
/> <span style={{position:'relative',top:'-3px',fontSize:'12px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>

</div>
))}




<div className="pt-2"></div>
<div className="lineInPermissions"><span>CONTAINERS PERMISSIONS</span></div>

{ props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag==="Container").map((list,index) => (

<div  className="mt-3 col-md-3" key={index}>
  {/* <Form.Check 
    type={'checkbox'}
    name="permission"
    id={list.name}
    label={list.name.charAt(0).toUpperCase()+list.name.slice(1)}
    value={list.id}
    onChange={handleCheck}
 
  /> */}


<Checkbox
key={list.id}
type="checkbox"
name={list.id}
id={list.id}
handleClick={handleClickCheck}
isChecked={isChecked.find(li=>li===list.id)}
/> <span style={{position:'relative',top:'-3px',fontSize:'12px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>

</div>
))}



<div className="pt-2"></div>
<div className="lineInPermissions"><span>SETTINGS PERMISSIONS</span></div>

{ props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag==="Settings").map((list,index) => (

<div  className="mt-3 col-md-3" key={index}>
  {/* <Form.Check 
    type={'checkbox'}
    name="permission"
    id={list.name}
    label={list.name.charAt(0).toUpperCase()+list.name.slice(1)}
    value={list.id}
    onChange={handleCheck}
 
  /> */}


<Checkbox
key={list.id}
type="checkbox"
name={list.id}
id={list.id}
handleClick={handleClickCheck}
isChecked={isChecked.find(li=>li===list.id)}
/> <span style={{position:'relative',top:'-3px',fontSize:'12px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>

</div>
))}




<div className="pt-2"></div>
<div className="lineInPermissions"><span>NOTIFICATIONS PERMISSIONS</span></div>

{ props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag==="Notification").map((list,index) => (

<div  className="mt-3 col-md-3" key={index}>
  {/* <Form.Check 
    type={'checkbox'}
    name="permission"
    id={list.name}
    label={list.name.charAt(0).toUpperCase()+list.name.slice(1)}
    value={list.id}
    onChange={handleCheck}
 
  /> */}


<Checkbox
key={list.id}
type="checkbox"
name={list.id}
id={list.id}
handleClick={handleClickCheck}
isChecked={isChecked.find(li=>li===list.id)}
/> <span style={{position:'relative',top:'-3px',fontSize:'12px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>

</div>
))}



  </div>






            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Register
            </button>
          </Modal.Footer>
        </form>
      </Modal>





{/* ======================
View Role permissions Modal
====================== */}
<Modal show={viewModal} onHide={viewModalClose} size="lg"  >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Role Info</Modal.Title>
          <CloseButton variant={"white"} onClick={viewModalClose}></CloseButton>
        </Modal.Header> 

   
          <Modal.Body >
         
     <div className="row modal-body"  style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}>
        
       {props.showPermissions?.rolePermissions.length!=0 ? <span className="pb-3 " style={{color:'#1163ab',fontWeight:'600',textAlign:'center',fontSize:'14px'}}>Total Permissions: {props.showPermissions?.rolePermissions.length}</span>:' The role has no Permissions'}

<div className="row">
{
props.showPermissions?.rolePermissions.map((per,index)=>{

  return (
<div className="col-md-3" key={index}>

<div className="pb-2">{index+1}. {per.name.charAt(0).toUpperCase()+per.name.slice(1)}</div>

</div>
  );
})






}  

</div>


    </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

        
     
      </Modal>








{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditModal} onHide={handleEditClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Role and Permissions </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitRoleUpdate}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-6 mb-3">
                <FloatingLabel label="Role Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="editRoleName"
                    value={roleNameEdit.name}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.roleUpdateErrors?.error?.name?true:false}
                  />

                </FloatingLabel>

                {props.roleUpdateErrors?.error?.name ? (
                  <div className="validation-error">
                    The role name already exists, you can't update it with this role name !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
     <div className="row border modal-body"  style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}>




  <div className="lineInPermissions"><span>SUPER ADMIN PERMISSIONS</span></div>


  {   props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag === "super-admin").map((list,index) => {
 
 return (

<div  className="mt-3 col-md-3" key={index}>
<Form.Check 
style={{border:'12px'}}
type={'checkbox'}
name="permission"
id={list.name}
label={<span style={{fontSize:'12px',position:'relative',bottom:'1px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>}
value={list.id}
defaultChecked={values?.find(e=>e.name===list.name?true:false)}
onChange={(e) =>  handleOnChangeCheckbox(e,index)}
onClick={(e) =>  handleOnChangeCheckbox(e)}

/>

</div>
 )
 }
 
)}





<div className="pt-2"></div>
<div className="lineInPermissions"><span>AUTOS PERMISSIONS</span></div>


         {   props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag === "Autos").map((list,index) => {
 
         return (

    <div  className="mt-3 col-md-3" key={index}>
      <Form.Check 
      style={{border:'12px'}}
        type={'checkbox'}
        name="permission"
        id={list.name}
        label={<span style={{fontSize:'12px',position:'relative',bottom:'1px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>}
        value={list.id}
        defaultChecked={values?.find(e=>e.name===list.name?true:false)}
        onChange={(e) =>  handleOnChangeCheckbox(e,index)}
        onClick={(e) =>  handleOnChangeCheckbox(e)}
      
      />
     
    </div>
         )
         }
         
  )}

<div className="pt-4"></div>

  <div className="lineInPermissions"><span>LOADING LISTS PERMISSIONS</span></div>


  {   props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag === "LoadingList").map((list,index) => {
 
 return (

<div  className="mt-3 col-md-3" key={index}>
<Form.Check 
style={{border:'12px'}}
type={'checkbox'}
name="permission"
id={list.name}
label={<span style={{fontSize:'12px',position:'relative',bottom:'1px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>}
value={list.id}
defaultChecked={values?.find(e=>e.name===list.name?true:false)}
onChange={(e) =>  handleOnChangeCheckbox(e,index)}
onClick={(e) =>  handleOnChangeCheckbox(e)}

/>

</div>
 )
 }
 
)}



<div className="pt-4"></div>

  <div className="lineInPermissions"><span>CONTAINERS PERMISSIONS</span></div>


  {   props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag === "Container").map((list,index) => {
 
 return (

<div  className="mt-3 col-md-3" key={index}>
<Form.Check 
style={{border:'12px'}}
type={'checkbox'}
name="permission"
id={list.name}
label={<span style={{fontSize:'12px',position:'relative',bottom:'1px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>}
value={list.id}
defaultChecked={values?.find(e=>e.name===list.name?true:false)}
onChange={(e) =>  handleOnChangeCheckbox(e,index)}
onClick={(e) =>  handleOnChangeCheckbox(e)}

/>

</div>
 )
 }
 
)}






<div className="pt-4"></div>

  <div className="lineInPermissions"><span>SETTINGS PERMISSIONS</span></div>


  {   props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag === "Settings").map((list,index) => {
 
 return (

<div  className="mt-3 col-md-3" key={index}>
<Form.Check 
style={{border:'12px'}}
type={'checkbox'}
name="permission"
id={list.name}
label={<span style={{fontSize:'12px',position:'relative',bottom:'1px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>}
value={list.id}
defaultChecked={values?.find(e=>e.name===list.name?true:false)}
onChange={(e) =>  handleOnChangeCheckbox(e,index)}
onClick={(e) =>  handleOnChangeCheckbox(e)}

/>


</div>
 )
 }
 
)}




<div className="pt-4"></div>

  <div className="lineInPermissions"><span>NOTIFICATIONS PERMISSIONS</span></div>


  {   props.rolesListSuccessResponse?.permissions?.filter((l)=>l.permission_flag === "Notification").map((list,index) => {
 
 return (

<div  className="mt-3 col-md-3" key={index}>
<Form.Check 
style={{border:'12px'}}
type={'checkbox'}
name="permission"
id={list.name}
label={<span style={{fontSize:'12px',position:'relative',bottom:'1px'}}>{list.name.charAt(0).toUpperCase()+list.name.slice(1)}</span>}
value={list.id}
defaultChecked={values?.find(e=>e.name===list.name?true:false)}
onChange={(e) =>  handleOnChangeCheckbox(e,index)}
onClick={(e) =>  handleOnChangeCheckbox(e)}

/>


</div>
 )
 }
 
)}





  
  </div>
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const { loading, rolesListSuccessResponse,showModal,roleRegisterErrors,roleUpdateErrors, roleRegisterSuccess, showPermissions, roleDeletedResponse,currentPage, showEditModal, name} = state.RolesHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    rolesListSuccessResponse: rolesListSuccessResponse,
    showModal:showModal,
    name:name,
    roleRegisterErrors:roleRegisterErrors,
    roleRegisterSuccess:roleRegisterSuccess,
    showPermissions:showPermissions,
    roleDeletedResponse:roleDeletedResponse,
    currentPage:currentPage,
    showEditModal:showEditModal,
    roleUpdateErrors:roleUpdateErrors,
    preDataLoadResponse:preDataLoadResponse
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rolesList: (pageNumber) => dispatch(rolesList(pageNumber)),
    showRoleModal: () => dispatch(showRoleModal()),
    hideRoleModal: () => dispatch(hideRoleModal()),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    registerRole:(name,permission) => dispatch(registerRole(name,permission)),
    showRolePermissions:(id)=>dispatch(showRolePermissions(id)),
    roleDelete:(id,currentPage)=>dispatch(roleDelete(id,currentPage)),
    showEditRoleModal:()=>dispatch(showEditRoleModal()),
    hideEditRoleModal:()=>dispatch(hideEditRoleModal()),
    roleUpdate:(id,name,permissions,currentPage)=>dispatch(roleUpdate(id,name,permissions,currentPage)),
    fetchDataBeforeLoadingPage: () =>
      dispatch(preDataLoad()),
      rolePicker:()=>dispatch(rolePicker())

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================