import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");


// ---------------------------------------------------------------------------------------------------------
// Users List in the system with pagination Action - the authenticated user would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const usersList = (pageNumber) => {
    return (dispatch) => {
      dispatch(fetchUsersListStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/list?page=${pageNumber}`, axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(fetchUsersListSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(fetchUsersListFailure(err.message));
        });
    };
  };
  
  const fetchUsersListStarted = () => ({
    type: "fetch_started",
  });
  
  const fetchUsersListSuccess = (data) => ({
    type: "fetch_success",
    payload: {
      ...data,
    },
  });
  
  const fetchUsersListFailure = (error) => ({
    type: "fetch_failure",
    payload: {
      error,
    },
  });
  





// ---------------------------------------------------------------------------------------------------------
// the user edit function will only fetch the data of a single user 
// ---------------------------------------------------------------------------------------------------------

export const userEdit = (id) => {

  return (dispatch) => {
    dispatch(userEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/updateDetails/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(userEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(userEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const userEditDetailsStarted = () => ({
  type: "userEditDetails_started",
});

const userEditDetailsSuccess = (data) => ({
  type: "userEditDetails_success",
  payload: {
    ...data,
  },
});

const userEditDetailsFailure = (error) => ({
  type: "userEditDetails_failure",
  payload: {
    error,
  },
});



// -------------------------------------------------------------------------
// for opening registration model
// -------------------------------------------------------------------------
export const hideUpdateModal=()=>{
  return {
    type:'hideModalAction'
  }
  
  }






// ---------------------------------------------------------------------------------------------------------
// the user update function will only fetch the data of a single user 
// ---------------------------------------------------------------------------------------------------------

export const userUpdate = (id,currentPage,name,username,email,contact,role,password,confirmPassword) => {
//console.log(name)
  return (dispatch) => {
    dispatch(userUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    let userData={
      name:name,
      username:username,
      email:email,
      role:role,
      contact:contact,
      password:password,
      confirm_password:confirmPassword,
 
     }


    axios
      .put(`${process.env.REACT_APP_API_ADDRESS}/updateUser/`+id,userData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);
          dispatch(userUpdateSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The user has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(userUpdateFailure(err.response.data.errors));
     
      });
  };
};

const userUpdateStarted = () => ({
  type: "userUpdate_started",
});


const userUpdateSuccess = (data,currentPage) => ({
  type: "userUpdate_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const userUpdateFailure = (error) => ({
  type: "userUpdate_failure",
  payload: {
    error,
  }, 
});








// ---------------------------------------------------------------------------------------------------------
// Users deletion in the system with pagination Action - the authenticated user would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const userDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(userDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteUser/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(userDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The user has been successfully deleted',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(userDeletionFailure(err.message));
      });
  };
};

const userDeletionStarted = () => ({
  type: "userDeletion_started",
});

const userDeletionSuccess = (data,currentPage) => ({
  type: "userDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const userDeletionFailure = (error) => ({
  type: "userDeletion_failure",
  payload: {
    error,
  },
});
