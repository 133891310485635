const InitialStates = {
    preDataLoadResponse:null,
    functionalLoader:false,
     loading: false,

     autoRegistrationSuccess:null,
     autoAdditionalRegistrationSuccess:null,
     autoRegistrationErrors:null,
     openAutoModal:false,
     openAutoEditModal:false,
     openAutoAdditionalModal:false,
     getAdditionalRegDataSuccess:null,
     name:'',
     currentPage:1,
   cleanValues:false,
   uploadPercentage:0,
   catagoryValue:false,
   openInvoiceInfo:false,
   openInvoiceReg:false,
   openAutoCommuncation:false,
   openEditNoteAuto:false,
   cleanAdditionalValues:false,
   nullValues:false,
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
       
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

       case 'openAutoModal':
        return { ...state, 'openAutoModal':true };

        case 'hideAutoModal':
          return { ...state, 'openAutoModal':false };

          case 'openAutoEditModal':
            return { ...state, 'openAutoEditModal':true };
    
            case 'hideAutoEditModal':
              return { ...state, 'openAutoEditModal':false };

              case 'openAutoAdditionalModal':
                return { ...state, 'openAutoAdditionalModal':true };
        
                case 'hideAutoAdditionalModal':
                  return { ...state, 'openAutoAdditionalModal':false };


                  case 'openInvoiceInformationModal':
                    return { ...state, 'openInvoiceInfo':true };
            
                    case 'hideInvoiceInformationModal':
                      return { ...state, 'openInvoiceInfo':false };


                  case 'openinvoiceRegistrationModal':
                    return { ...state, 'openInvoiceReg':true };
            
                    case 'hideinvoiceRegistrationModal':
                      return { ...state, 'openInvoiceReg':false };


                      case 'openAutoCommuncationModal':
                        return { ...state, 'openAutoCommuncation':true };
                
                        case 'hideAutoCommuncationModal':
                          return { ...state, 'openAutoCommuncation':false };



                          case 'openEditNoteAutoModal':
                            return { ...state, 'openEditNoteAuto':true };
                    
                            case 'hideEditNoteAutoModal':
                              return { ...state, 'openEditNoteAuto':false };

//=============================               
//for loading purposes
//=============================
          case "autos_fetch_started":
        
            return { ...state, 'loading':true };

            case "filterOnWarehouses_started":
        
              return { ...state, 'loading':true };

              
              case "searchAutos_started":
        
                return { ...state, 'loading':true };

                case "changePointOfLoading_started":
        
                  return { ...state, 'loading':true };


                case "autoAdditionalReg_started":
        
                  return { ...state, 'functionalLoader':true };



                  case "storeAutoData_started":
        
                    return { ...state, 'functionalLoader':true };


                    case "imagesDeletion_started":
        
                      return { ...state, 'functionalLoader':true };


                      case "selectedAutos_started":
        
                        return { ...state, 'loading':true };

                        case "getCommunicationAuto_started":
        
                          return { ...state, 'functionalLoader':true };

                          case "autoDelete_started":
        
                            return { ...state, 'loading':true };

                            case "autoCommunication_started":
        
                              return { ...state, 'functionalLoader':true };


                              case "editSelectedAutoNotes_started":
        
                              return { ...state, 'functionalLoader':true };


                              case "deleteSelectedAutoNotes_started":
        
                                return { ...state, 'functionalLoader':true };


                                case "storeAutosImages_started":
        
                                  return { ...state, 'functionalLoader':true };
  

                                  case "storeAutosImages_started":
        
                                    return { ...state, 'functionalLoader':true };

                  // case "zipImages_started":
        
                  //   return { ...state, 'functionalLoader':true };
                
//================ends here==================================
  case "images_fetch_success_autos":
  return { ...state, 'autosImagesResponse': action.payload, 'functionalLoader':false, 'loading':false};
  
  case "storeAutosImages_success":
    return { ...state, 'autosImagesUploadResponse': action.payload, 'loading':false,  'functionalLoader':false,  'cleanAdditionalValues':true };
  
  

            case "autos_fetch_success":
        
              return { ...state, 'autosListResponse': action.payload, 'loading':false, 'functionalLoader':false, 'catagoryValue':false };

              case "filterOnWarehouses_success":
        
                return { ...state, 'autosListResponse': action.payload, 'loading':false,  'functionalLoader':false, 'catagoryValue':true };
          
           
      case "getAutoAdditionalReg_success":

        return { ...state, 'getAdditionalRegDataSuccess': action.payload, 'loading':false, 'functionalLoader':false, };


        case "changePointOfLoading_success":

          return { ...state, 'pointOfLoadingChangeSuccess': action.payload, 'loading':false,'functionalLoader':false, 'openAutoEditModal':false, 'currentPage':action.payload.currentPage  };
  
  
        

         case "storeAutoData_success":
        
         return { ...state, 'autoRegistrationSuccess': action.payload, 'loading':false , 'functionalLoader':false,'autoRegistrationErrors':null,'openAutoModal':false, 'cleanValues':true  };
       

         case 'upload_progress':
          return { ...state, 'uploadPercentage':action.payload };

          
          case 'resetProgressBar':
            return { ...state, 'uploadPercentage':0 };

         case "autoAdditionalReg_success":
        
          return { ...state, 'autoAdditionalRegistrationSuccess': action.payload, 'loading':false , 'functionalLoader':false, 'openAutoAdditionalModal':false, 'currentPage':action.payload.currentPage, 'autoRegistrationErrors':null, 'cleanAdditionalValues':true, 'uploadPercentage':100 };
    
       case 'images_fetch_success_autos':
       return { ...state, 'getImagesSuccess': action.payload, 'loading':false, 'functionalLoader':false,  };

      
          case "storeAutoData_failure":
      
         return { ...state, 'autoRegistrationErrors': action.payload, 'loading':false ,'functionalLoader':false,};
          
    


          case "roleDeletion_success":
          
          return { ...state, 'roleDeletedResponse': action.payload, 'currentPage':action.payload.currentPage};

          case 'imagesDeletion_success':

          return { ...state, 'imagesDeletionSuccess': action.payload, 'loading':false, 'functionalLoader':false,};

          case "autoDelete_success":
            return { ...state, 'autoDeleted': action.payload, 'loading':false, 'functionalLoader':false,};

            case "selectedAutos_success":
              return { ...state, 'selectedAutosDeleted': action.payload, 'loading':false, 'functionalLoader':false, 'selectedArray':''};
  
  

// =================================================
// Autos communcation reducers
// =================================================

              case "getCommunicationAuto_success":
                return { ...state, 'getAutoListCommunication': action.payload, 'functionalLoader':false,  'loading':false };
              
              
              case "autoCommunication_success":
                return { ...state, 'registerNoteResponse': action.payload, 'functionalLoader':false, 'loading':false, 'textAreaValue':'' };
              
              
              case "deleteSelectedAutoNotes_success":
                return { ...state, 'deletedSelectedNotes': action.payload, 'functionalLoader':false, 'loading':false, 'textAreaValue':'' };
              case "getEditAutoNoteDetails_success":
                return { ...state, 'getEditAutoNoteInfo': action.payload, 'functionalLoader':false, 'loading':false};
              
              
              case "editSelectedAutoNotes_success":
                return { ...state, 'editedSelectedNote': action.payload, 'functionalLoader':false, 'loading':false,'openEditNoteAuto':false, 'attachmentValue':''  };
              





case "usersSelectorList_success":

  return { ...state, 'usersSelectorListResponse': action.payload, 'functionalLoader':false, 'loading':false,};

//for searching users list 
case "searchAutos_success":
       
  return { ...state, 'autosListResponse': action.payload,'functionalLoader':false,  'loading':false, 'currentPage':action.payload.data.current_page };



    

    
       default:
         return state;
     }
   };
   